import { Box, Grid, useMediaQuery } from '@mui/material';
import { Drawer } from '@confidant-health/lib/ui/organisms/drawer';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeSize, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { S3_BUCKET_URL } from '@confidant-health/lib/constants/CommonConstants';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';

import { useEffect, useState } from 'react';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import { getProviderFeeList } from 'services/provider-fee/providerFee.service';
import { getDateLabels } from 'utils/CommonUtils';
import dayjs, { Dayjs } from 'dayjs';
import useDebounce from 'hooks/useDebounce';
import { showSnackbar } from 'redux/modules/snackbar';
import { profileActionCreators } from 'redux/modules/profile';
import avatarIcon from 'assets/images/avatar.png';
import history from 'utils/history';
import DatePickerInput2 from './DatePicker2';
import DrawerHead from './DrawerHead';
import ReviewCard from './Card';

import { useStyles } from '../index.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};

const AllPaymentsDrawer = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [paymentList, setPaymentList] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>({});
  const [searchKey, setSearchKey] = useState('');
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedDate2, setSelectedDate2] = useState<Dayjs | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const searchName = useDebounce(searchKey, 1000);

  const fetchProviderPayments = async () => {
    try {
      const queryParams = {
        page,
        limit: 10,
      };
      const body = {
        searchQuery: searchKey,
        startDate: !selectedDate ? null : selectedDate.format(),
        endDate: !selectedDate2 ? null : selectedDate2.format(),
        statuses: [],
        states: [],
        roles: [],
        providerIds: [meta.userId],
      };
      const { data } = await getProviderFeeList(queryParams, body);
      setPage(page + 1);
      setPaymentList([...paymentList, ...data?.data]);
      if (!isDrawerOpen) {
        setMetaData(data?.metadata);
        setIsDrawerOpen(true);
      }
      const totalPagess = data?.metadata?.count / 10;
      setTotalPages(totalPagess);
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.warn(e);
    }
  };

  // const debouncedFetchProviderPayments = debounce(fetchProviderPayments, 2000);

  const onChangeSearchKey = (val: string) => {
    setSearchKey(val);
    setPaymentList([]);
    setPage(1);
    setTotalPages(0);
  };

  useEffect(() => {
    if (open) void fetchProviderPayments();
  }, [open, selectedDate, selectedDate2, searchName]);

  const gotoMemberDetail = async payment => {
    const queryParams = {
      searchQuery: payment?.member?.firstName || payment?.member?.nickName,
      pageNumber: 1,
      pageSize: 50,
      orderBy: '',
      sortBy: '',
    };
    await dispatch(profileActionCreators.fetchPatients(queryParams));
    setTimeout(function () {
      history.push(`/provider/members/${payment?.member?.id}?name=dashboard`);
    }, 1500);
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        setPaymentList([]);
        setPage(1);
        setTotalPages(0);
        setSearchKey('');
        setSelectedDate(null);
        setSelectedDate2(null);
        setIsDrawerOpen(false);
        onClose();
      }}
      className={classes.drawer}
    >
      <DrawerHead
        title="All payments"
        onClose={() => {
          setPaymentList([]);
          setPage(1);
          setTotalPages(0);
          setSearchKey('');
          setSelectedDate(null);
          setSelectedDate2(null);
          setIsDrawerOpen(false);
          onClose();
        }}
      />
      <Box p={3} display="flex" flexDirection="column" sx={{ overflowY: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ReviewCard
              label="total amount earned"
              count={`$${metaData?.totalFee ? metaData?.totalFee : '0'}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReviewCard label="Total Appointments" count={metaData?.count ? metaData?.count : '0'} />
          </Grid>
        </Grid>
        <Box display="flex" gap={2} alignItems="center">
          <Input
            className={classes.inputSearch}
            variant={inputType.SEARCH}
            placeholder="Search"
            value={searchKey}
            onChange={onChangeSearchKey}
            size={inputSize.S}
            fullWidth
          />
          {/* <IconButton icon="calendar-primary-outlined" variant={btnType.OUTLINE} className={classes.btn}>
            <Typography {...typography.body.s.medium}>All time</Typography>
          </IconButton> */}
          <Box>
            <DatePickerInput2
              placeHolder="From date"
              date={selectedDate}
              onChange={date => {
                setPaymentList([]);
                setPage(1);
                setTotalPages(0);
                setSelectedDate(date);
              }}
            />
          </Box>
          <Box>
            <DatePickerInput2
              placeHolder="Till date"
              date={selectedDate2}
              onChange={date => {
                setPaymentList([]);
                setPage(1);
                setTotalPages(0);
                setSelectedDate2(date);
              }}
            />
          </Box>
        </Box>
        <Grid className={classes.dataContainer}>
          <Grid padding="10px">
            {paymentList.map((payment, key) => (
              <Grid
                key={key}
                item
                container
                display="flex"
                alignItems="center"
                borderBottom={`1px solid ${colors.neutral100}`}
              >
                <Grid item xs={12} md={3} padding={2}>
                  {/* <ProfileInfo
                    type="member"
                    photo={payment?.member?.profileImage}
                    nickName={payment?.member?.nickName}
                    fullName={`${payment?.member?.uuid} - ${dayjs(payment?.member?.dob).format(
                      'MM/DD/YYYY',
                    )}`}
                  /> */}
                  <Box
                    display="flex"
                    gap={1.5}
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      void gotoMemberDetail(payment);
                    }}
                  >
                    <img
                      alt="none avatar icon"
                      className={classes.imageContainer}
                      src={
                        payment?.member?.profileImage
                          ? `${S3_BUCKET_URL}${payment?.member?.profileImage}`
                          : avatarIcon
                      }
                    />
                    <Box display="flex" flexDirection="column" flex={1}>
                      <Typography {...typography.body.normal.small.semibold}>
                        {payment?.member?.firstName && payment?.member?.lastName
                          ? `${payment?.member?.firstName || ''} ${payment?.member?.lastName || ''}`
                          : payment?.member?.Nickname || 'No Name'}
                      </Typography>
                      <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                        {`${payment?.member?.uuid} - ${dayjs(payment?.member?.dob).format('MM/DD/YYYY')}`}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={1} padding={2}>
                  <Typography {...typography.body.m.bold}>${payment?.amount}</Typography>
                </Grid>
                <Grid item xs={12} md={3} padding={2}>
                  <Typography {...typography.body.normal.small.medium}>{payment?.sessionType}</Typography>
                </Grid>
                <Grid item xs={12} md={2} padding={2} className={isMd ? '' : classes.paymentBadgeContainer}>
                  <Badge
                    variant={badgeType.FILLED}
                    size={badgeSize.XSMALL}
                    className={
                      payment?.status === 'Paid'
                        ? classes.badgeStyle1
                        : payment?.status === 'Submitted' || payment?.status === 'Created'
                        ? classes.badgeStyle2
                        : classes.badgeStyle3
                    }
                  >
                    <Typography {...typography.body.normal.xsmall.semibold}>
                      {payment?.status === 'Paid'
                        ? 'Paid'
                        : payment?.status === 'Submitted' || payment?.status === 'Created'
                        ? 'Pending'
                        : 'Rejected'}
                    </Typography>
                  </Badge>
                </Grid>
                <Grid item xs={12} md={3} padding={2}>
                  <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                    {getDateLabels(payment?.timeStamp)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {paymentList.length === 0 && (
              <Grid className={classes.noResult}>
                <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                  No payments available
                </Typography>
              </Grid>
            )}
          </Grid>
          {totalPages !== 0 && page <= totalPages && (
            <Box className={classes.btnContainer}>
              <Button
                variant={btnType.TEXT}
                className={classes.loadMoreBtn}
                onClick={() => void fetchProviderPayments()}
              >
                Load more
              </Button>
            </Box>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};

export default AllPaymentsDrawer;
