import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { getConversation } from 'redux/modules/conversation/selectors';
import { formatTimeMessage } from 'utils/CommonUtils';
import { UserChatT } from './Chats.types';
import { useStyles } from './Chats.styles';
import { ChatThreadMenu } from './ChatThreadMenu';
import { TodoDialog } from './TodoDialog';

const UserChatCard: React.FC<UserChatT> = ({
  nickName,
  createdDate,
  message,
  user,
  thumbnail,
  fileMeta,
  colorCode,
  messageId,
  mentionedUsers,
  recentMessageSend,
  selectedContact,
  setMessageThread,
  senderId,
  currentUserId,
  inputRef2,
  prevMsg,
  scrollMessageId,
  getConnectionDetailsByUserId,
}) => {
  const classes = useStyles({});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [highlighted, setHighlighted] = useState(false);
  const { allTodos } = useSelector(getConversation);
  const cardRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (cardRef.current) {
      if (!!scrollMessageId && Number(scrollMessageId) === messageId) {
        setTimeout(() => {
          cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setTimeout(() => {
            setHighlighted(true);
            setTimeout(() => {
              setHighlighted(false);
            }, 2000);
          }, 200);
        }, 2000);
      }
    }
  }, [cardRef.current]);

  const convertToLinks = text => {
    if (!text) return text;
    const urlRegex =
      /((?:https?:\/\/)?(?:www\.[^\s]+)|(?:www\.[^\s]+)|[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})[^\s]*)/g;
    const parts = text.split(' ');
    const convertedParts = parts.map((part, index) => {
      if (part.match(urlRegex)) {
        const url = part.startsWith('https') || part.startsWith('http') ? part : `https://${part}`;
        return (
          <span key={index}>
            <a href={url} target="_blank" className={classes.link} rel="noreferrer">
              {part}
            </a>{' '}
          </span>
        );
      }
      return <span key={index}>{`${part} `}</span>;
    });
    return convertedParts;
  };

  const highlightMentionedUsers = (sentence, users) => {
    // const sentencee = convertToLinks(sentence);
    // console.log(sentencee);
    const words = sentence?.split(' ');
    const mentionUsers = users?.map(provider => provider.nickname);
    const elements = [];
    words?.forEach(word => {
      if (word.startsWith('@') && mentionUsers?.length !== 0) {
        elements.push(
          <span key={word} className="highlightMentionedUser">
            {word}
          </span>,
        );
      } else {
        elements.push(convertToLinks(word));
      }
      elements.push(' ');
    });
    return <>{elements}</>;
  };

  const todoDate = id => {
    const todoDate1 = allTodos?.find(item => Number(item?.contentIdentifier) === id);
    if (todoDate1?.dueDate !== undefined && todoDate1?.dueDate !== null) {
      const todoDate2 = dayjs(todoDate1?.dueDate?.replaceAll('-', '/')).format('YYYY-MM-DD');
      const currentDate = dayjs().format('YYYY-MM-DD');
      const tomorrowDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
      let date1 = '';
      if (currentDate === todoDate2) {
        date1 = '- Today';
      } else if (tomorrowDate === todoDate2) {
        date1 = '- Tomorrow';
      } else if (dayjs(todoDate1?.dueDate).isBefore(currentDate)) {
        date1 = '- Past due';
      } else {
        date1 = `- ${dayjs(todoDate1?.dueDate?.replaceAll('-', '/')).format('MM-DD-YYYY')}`;
      }
      return date1;
    }
    return '';
  };

  return (
    <>
      <div
        ref={cardRef}
        key={messageId}
        className={clsx(classes.userChatCard, {
          [classes.highlighted]: highlighted,
        })}
        onMouseEnter={() => setHoveredIndex(messageId)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <div>
          <Avatar
            variant={avatarType.CIRCLE}
            size={40}
            src={thumbnail}
            name={nickName === 'Super Admin' || nickName === 'Confidant Admin' ? 'Confidant Admin' : nickName}
            className={classes.avt}
            color={colorCode}
          />
        </div>
        <div className="userChatContentWrapper">
          <div
            className={
              location.pathname.includes('/appointments/current')
                ? 'userChatMessageWrapper3'
                : 'userChatMessageWrapper'
            }
          >
            <div className="userChatNameWrapper">
              {!!nickName && (
                <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                  {nickName === 'Super Admin' || nickName === 'Confidant Admin'
                    ? 'Confidant Admin'
                    : nickName}
                </Text>
              )}
              <Text className="time" weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
                {formatTimeMessage(createdDate)}
              </Text>
            </div>
            {selectedContact?.type === 'PATIENT' ? (
              <Text className={classes.messageText} weight={fontWeight.MEDIUM} level={textLevel.S}>
                {convertToLinks(message.text)}
              </Text>
            ) : (
              <Text className={classes.messageText} weight={fontWeight.MEDIUM} level={textLevel.S}>
                {location.pathname === '/provider/chats' || location.pathname === '/admin/chats'
                  ? highlightMentionedUsers(message.text, mentionedUsers)
                  : convertToLinks(message.text)}
                {location.pathname === '/provider/chats' || location.pathname === '/admin/chats' ? (
                  <span className={classes.todoDates}>{todoDate(messageId)}</span>
                ) : (
                  ''
                )}
              </Text>
            )}
            {fileMeta && (
              <>
                {fileMeta?.type.split('/')[0] === 'image' ? (
                  <img src={fileMeta.url} alt="confidant health" height={200} width={350} />
                ) : (
                  <video controls src={fileMeta.url} height={200} width={350} />
                )}
              </>
            )}
          </div>
        </div>
        {(location.pathname === '/provider/chats' || location.pathname === '/admin/chats') && (
          <>
            {messageId === hoveredIndex && (
              <div className={classes.chatMessageButtonConatiner}>
                <div>
                  <Button
                    variant="outlined"
                    className={classes.replyButton}
                    onClick={() => {
                      inputRef2?.current?.focus();
                      setMessageThread({
                        messageId,
                        message: message?.text,
                        nickName,
                        fileMeta,
                        colorCode,
                        userId: user?.userId,
                        name: user?.name,
                        avatar: user?.avatar,
                        thumbnail,
                        createdDate,
                      });
                    }}
                  >
                    <Icons glyph="reply-thread" color={colors.primary600} />
                  </Button>
                </div>
                {mentionedUsers !== undefined && (
                  <>
                    {mentionedUsers?.length !== 0 && senderId === currentUserId && (
                      <ChatThreadMenu id={messageId} />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {recentMessageSend?.includes(messageId) && (
        <TodoDialog type="Parent" msg={message.text} id={messageId} currentUserId={currentUserId} />
      )}
      {(location.pathname === '/provider/chats' || location.pathname === '/admin/chats') && (
        <>
          {prevMsg !== undefined && (
            <>
              {prevMsg?.length !== 0 && (
                <>
                  {Object.keys(prevMsg[0])?.length !== 0 && (
                    <div key={messageId} className={classes.userChatCard2}>
                      <Avatar
                        variant={avatarType.CIRCLE}
                        size={40}
                        src={!prevMsg[0]?.avatar?.includes('/sample/') ? prevMsg[0].avatar : ''}
                        name={
                          prevMsg[0]?.nickName === 'Super Admin' || prevMsg[0]?.nickName === 'Confidant Admin'
                            ? 'Confidant Admin'
                            : prevMsg[0]?.nickName
                        }
                        className={classes.avt}
                        color={
                          currentUserId !== prevMsg[0]?.userId
                            ? getConnectionDetailsByUserId(prevMsg[0]?.userId)?.colorCode
                            : null
                        }
                      />
                      <div className="userChatContentWrapper">
                        <div className="userChatMessageWrapper">
                          <div className="userChatNameWrapper">
                            {!!prevMsg[0]?.nickName && (
                              <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                                {prevMsg[0]?.nickName === 'Super Admin' ||
                                prevMsg[0]?.nickName === 'Confidant Admin'
                                  ? 'Confidant Admin'
                                  : prevMsg[0]?.nickName}
                              </Text>
                            )}
                            <Text className="time" weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
                              {formatTimeMessage(prevMsg[0]?.createdDate)}
                            </Text>
                          </div>
                          {selectedContact?.type === 'PATIENT' ? (
                            <Text
                              className={classes.messageText}
                              weight={fontWeight.MEDIUM}
                              level={textLevel.S}
                            >
                              {convertToLinks(prevMsg[0]?.message)}
                            </Text>
                          ) : (
                            <Text
                              className={classes.messageText}
                              weight={fontWeight.MEDIUM}
                              level={textLevel.S}
                            >
                              {highlightMentionedUsers(prevMsg[0]?.message, prevMsg[0]?.mentionedUsers)}
                            </Text>
                          )}
                          {prevMsg[0]?.fileMeta && (
                            <>
                              {prevMsg[0]?.fileMeta?.type.split('/')[0] === 'image' ? (
                                <img
                                  src={prevMsg[0]?.fileMeta.url}
                                  alt="confidant health"
                                  height={200}
                                  width={350}
                                />
                              ) : (
                                <video controls src={prevMsg[0]?.fileMeta.url} height={200} width={350} />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserChatCard;
