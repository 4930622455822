import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeStyle } from '@confidant-health/lib/ui/atoms/badge';
import { Rating } from '@confidant-health/lib/ui/atoms/rating';
import { IconLink, iconLinkPosition } from '@confidant-health/lib/ui/molecules/icon-link';
import { IParticipant } from 'redux/modules/appointment/types';
import { getAuth } from 'redux/modules/auth/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import CreateConnectionModal from 'layouts/base/CreateConnectionModal';
import { createMemberConnection } from 'services/conversation/conversation.service';
import { showSnackbar } from 'redux/modules/snackbar';
import history from 'utils/history';
import { getDemographicDetails } from 'services/member/member.service';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
// styles
import { useStyles } from './Feedback.styles';

const Feedback: FC<IParticipant> = ({
  appointmentFeedback,
  isPractitioner,
  name,
  participantId,
  profilePicture,
  rating,
  dateOfBirth,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRole = location?.pathname.split('/')[1];
  const { isAdmin, meta } = useSelector(getAuth);
  const [existedMember, setExistedMember] = useState(null);
  const [isCreateConnection, setIsCreateConnection] = useState(false);
  const {
    payload: { patientsList },
    providers,
  } = useSelector(getProfile);

  useEffect(() => {
    if (isPractitioner) {
      const foundProvider = providers?.find(p => p.providerId === participantId);
      setExistedMember({
        member: {
          nickName: foundProvider?.fullName,
          firstName: foundProvider?.firstName,
          lastName: foundProvider?.lastName,
          id: foundProvider?.providerId,
          fullName: foundProvider?.fullName,
        },
        designation: foundProvider?.designation,
        phone: foundProvider?.phoneNumber,
        email: foundProvider?.emailAddress,
        activeChat: foundProvider?.active,
        action: { id: foundProvider?.providerId },
      });
      return;
    }
    const foundPatient = patientsList?.find(p => p.member?.id === participantId);
    if (foundPatient) {
      setExistedMember(foundPatient);
      return;
    }
    getDemographicDetails({ connectionId: participantId })
      .then(res => {
        setExistedMember({
          member: {
            nickName: res.data?.nickName,
            firstName: res.data?.firstName,
            lastName: res.data?.lastName,
            id: participantId,
            fullName: res.data?.fullName,
            dateOfBirth: res.data?.dateOfBirth,
            uuid: res?.data?.uuid,
          },
          phone: res.data?.mobilePhone,
          email: res.data?.email,
          action: { id: participantId },
        });
      })
      .catch(err => console.log({ err }));
  }, [patientsList]);

  const handleChatBtn = async () => {
    try {
      const { data } = await getDemographicDetails({ connectionId: participantId });
      if (meta?.authority === 'BILLING_SUPPORT' || meta?.authority === 'ADMIN') {
        if (data?.isConnected === false) {
          setIsCreateConnection(true);
        } else {
          history.push(`/${userRole}/chats?userId=${participantId}&type=members`);
        }
      } else {
        history.push(`/${userRole}/chats?userId=${participantId}&type=members`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addConnectionWithMember = async () => {
    try {
      const bodyRequest = {
        suggestedFirstUser: meta.userId,
        suggestedSecondUser: participantId,
      };
      const response = await createMemberConnection(bodyRequest);
      setIsCreateConnection(false);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Connection with member created successfully.',
          }),
        );
        history.push(`/admin/chats?userId=${participantId}&type=members`);
      }
    } catch (error) {
      setIsCreateConnection(false);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error?.data?.errors[0].endUserMessage,
        }),
      );
    }
  };

  const onClickMember = () => {
    if (!isPractitioner && participantId) {
      history.push(
        isAdmin
          ? `/admin/members/${participantId}?name=general-information`
          : `/provider/members/${participantId}?name=dashboard`,
      );
    }
  };

  return (
    <>
      <Box className={classes.card}>
        <Box className={classes.cardTop} display="flex" justifyContent="space-between" alignItems="center">
          <Text className={classes.cardTopTitle} weight={fontWeight.MEDIUM} level={textLevel.S}>
            {isPractitioner ? "Provider's feedback" : "Member's feedback"}
          </Text>
          {!isPractitioner && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconLink
                // href={`/${userRole}/chats?userId=${participantId}&type=members`}
                icon="chat-square"
                iconPosition={iconLinkPosition.RIGHT}
                className={classes.btnLink}
                onClick={handleChatBtn}
              >
                Message {isPractitioner ? 'provider' : 'member'}
              </IconLink>
            </Box>
          )}
        </Box>
        <Divider />
        <Box className={classes.cardBody}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="12px">
              <Avatar variant={avatarType.CIRCLE} size={40} src={profilePicture} name={name} />
              <Box onClick={onClickMember} sx={{ cursor: isPractitioner ? 'default' : 'pointer' }}>
                <Text className={classes.username} level={textLevel.S}>
                  {existedMember && isPractitioner
                    ? existedMember?.member?.nickName
                    : existedMember?.member?.firstName && existedMember?.member?.lastName
                    ? `${existedMember?.member?.firstName || ''} ${existedMember?.member?.lastName || ''}`
                    : existedMember?.member?.fullName}
                </Text>
                {existedMember?.designation ? (
                  <Badge style={badgeStyle.PURPLE}>{existedMember?.designation}</Badge>
                ) : (
                  <Text className={clsx(classes.username, classes.usernameFull)} level={textLevel.S}>
                    {existedMember?.member?.uuid || ''}
                  </Text>
                )}
              </Box>
            </Box>
            {rating > 0 && <Rating value={rating} className={classes.rating} />}
          </Box>
          <Text className={classes.cardBodyText} weight={fontWeight.MEDIUM} level={textLevel.S}>
            {appointmentFeedback?.toString()}
          </Text>
          {dateOfBirth && (
            <Box display="flex" gap={1} paddingTop={1}>
              <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
                DOB
              </Text>
              <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S}>
                {dateOfBirth !== null ? dayjs(dateOfBirth).format(GLOBAL_DATE_FORMAT) : '-'}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      {isCreateConnection && (
        <CreateConnectionModal
          icon="conversations-filled"
          open={isCreateConnection}
          title="Add Connection"
          content="Do you want to create connection with this member?"
          lbtnLabel="Cancel"
          rbtnLabel="Confirm"
          onClose={() => setIsCreateConnection(false)}
          onCancel={() => setIsCreateConnection(false)}
          onSubmit={addConnectionWithMember}
          isLoading={false}
          isDelete={false}
        />
      )}
    </>
  );
};

export { Feedback };
