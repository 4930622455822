import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './Chats.styles';
import { IChatListItem } from './Chats.types';

type Props = {
  chatListItem: IChatListItem;
  isChatSelected?: boolean;
  noRightAction?: boolean;
  onClick?: (chatListItem: IChatListItem) => void;
};

const ChatListItem: FC<Props> = ({ chatListItem, isChatSelected, noRightAction = false, onClick }) => {
  const classes = useStyles();

  /**
   * @Name onSelect
   * @description This method is used to select value from the dropdown
   */
  const onSelect = () => {
    if (onClick) {
      onClick(chatListItem);
    }
  };
  return (
    <Box className={classes.chatListItem} onClick={onSelect}>
      <Box className={classes.chatListItemFlex} gap="12px">
        <Avatar
          src={chatListItem?.avatar}
          name={chatListItem?.name}
          size={40}
          variant={avatarType.CIRCLE}
          color={chatListItem?.colorCode}
        />
        <Box>
          <Heading weight={fontWeight.SEMI_BOLD} className={classes.chatUsername}>
            {chatListItem?.name}
          </Heading>
          <Badge variant={badgeType.FILLED} className={clsx(classes.chatBadge, chatListItem?.role)}>
            {chatListItem?.role}
          </Badge>
        </Box>
      </Box>
      <Box className={classes.chatListItemFlex}>
        {isChatSelected ? (
          <Icons glyph="checkmark" color={colors.primary} />
        ) : (
          <>
            {!noRightAction && !chatListItem.isAdded && (
              <IconButton
                onClick={() => {
                  onClick(chatListItem);
                }}
                icon="plus"
                className={classes.iconBtn}
              >
                Add chat
              </IconButton>
            )}
            {!noRightAction && chatListItem.isAdded && (
              <IconButton
                onClick={() => {
                  onClick(chatListItem);
                }}
                icon="close-circle-outlined"
                className={clsx(classes.iconBtn, classes.deleteChatBtn)}
              >
                Delete chat
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export { ChatListItem };
