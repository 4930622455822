import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { useQuery } from '@confidant-health/lib/hooks';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { showSnackbar } from 'redux/modules/snackbar';
import { profileActionCreators } from 'redux/modules/profile';
import { getAppointment } from 'redux/modules/appointment/selectors';
import history from 'utils/history';
import { patchAppointment } from 'services/appointment/appointment.service';
import { getAppointmentEvaluationProgress } from 'services/conversation/conversation.service';
import { OPENTOK_APIKEY } from 'constants/CommonConstants';
import { getCareTeamMember } from 'services/member/member.service';
import { getUserTimeZone } from 'utils/dayjs';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import dayjs from 'dayjs';
import AppointmentInfo from './appointment-info';
import SessionFilters from './session-filters';
import WaitingHeader from './waiting-header';
import VideoCallScreen from './video-call-screen';
import EndSession from './end-session';
import WaitingRoomTopBar from './end-session-topbar';
import FeedbackAppointment from '../appointments/components/feedback-appointment';
import { EVALUATION_STATUS } from './evaluations-v2/Evaluations.constants';
import { FilterSessions, FilterSessionsMock } from './session-filters/SessionFilters.constants';
import { useStyles } from './Sessions.styles';
import { PatchAction } from '../../../redux/modules/appointment/types';
import { IEvaluationItem } from './evaluations-v2/Evaluations.types';
import { ProvideEvaluationHead } from '../../../hooks/useEvaluationHead';
// import evaluation from '@confidant-health/lib/icons/glyphs/Evaluation';

const Sessions: FC = () => {
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const { profile, demographicDetails } = useSelector(getProfile);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { isAdmin, meta } = useSelector(getAuth);
  const [selectedSession, setSelectedSession] = useState(FilterSessions[0].type);
  const [appointment, setAppointment] = useState<IAppointment>(null);
  const [isEndedSession, setIsEndedSession] = useState(false);
  const [showSignOff, setShowSignOff] = useState(false);
  const [showEndSessionConfirm, setShowEndSessionConfirm] = useState(false);
  const { appointments: appointmentsState = [] } = useSelector(getAppointment);
  const [telesessionToken, setTelesessionToken] = useState('');
  const [telesessionId, setTelesessionId] = useState('');
  const [startSession, setStartSession] = useState(false);
  const { appointmentId } = useParams();
  const [isWaiting, setIsWaiting] = useState(true);
  const [isInitializing, setIsInitializing] = useState(true);
  const [showFeedbackAppointment, setShowFeedbackAppointment] = useState(false);
  const [isSubmittedFeedback, setIsSubmittedFeedback] = useState(false);
  const [sessionFilterList, setSessionFilterList] = useState([]);
  const [careTeamListIds, setCareTeamListIds] = useState([]);
  const [session, setSession] = useState(null);
  const [chatsDrawerOpen, setChatsDrawerOpen] = useState(false);
  const [callEndedReason, setCallEndedReason] = useState(null);
  const [inPersonSession, setInPersonSession] = useState(false);
  const [showInPerson, setShowInPerson] = useState(false);
  const [initialEvaluation, setInitialEvaluation] = useState<IEvaluationItem>(null);

  useEffect(() => {
    const name = query.get('name');
    if (name) {
      setIsEndedSession(true);
      setSelectedSession(name);
    }
  }, []);

  /**
   * @Name onEndSession
   * @description This method is used to end tele session
   */
  const onEndSession = (endedVia: string = null) => {
    session.signal(
      {
        type: 'session-ended',
        data: 'Call Disconnected',
      },
      () => {
        patchAppointment({ action: PatchAction.COMPLETE_APPOINTMENT }, { appointmentId })
          .then(response => {
            if (response.status === 200) {
              setShowEndSessionConfirm(false);
              setIsEndedSession(true);
              setSelectedSession(FilterSessionsMock[0].type);
              setShowFeedbackAppointment(true);
              setIsWaiting(false);
              patchAppointment(
                {
                  action: PatchAction.PROVIDER_SIGN_OFF,
                  skipProviderSignOff: true,
                },
                {
                  appointmentId: appointment.appointmentId,
                },
              )
                .then(() => console.log('Moved to pending'))
                .catch(() => console.log('Whoops ! something went wrong when moving to pending'));
            }
          })
          .catch(err => {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Whoops ! something went wrong please try again',
              }),
            );
            setShowEndSessionConfirm(false);
            console.log('Whoops ! something went wrong please try again', err);
          });
      },
    );
  };

  /**
   * @Name toggleEndSessionConfirm
   * @description This method is used to show end session popup and navigate to next screen
   */
  const toggleEndSessionConfirm = (endedVia: string = null) => {
    if (!isEndedSession) {
      setShowEndSessionConfirm(s => !s);
      setCallEndedReason(endedVia);
    }
  };

  const startInPersonCall = () => {
    setIsWaiting(false);
    setSelectedSession('evaluation');
    setStartSession(true);
    setInPersonSession(true);
    void patchAppointment({ action: PatchAction.START_IN_PERSON }, { appointmentId });
  };

  const onStartSession = (val: boolean) => {
    setStartSession(val);
  };

  const onCloseSession = () => {
    setSelectedSession(FilterSessions[0].type);
  };

  const onCloseFeedbackAppointment = (isSubmitted = false) => {
    setShowFeedbackAppointment(false);
    setIsSubmittedFeedback(isSubmitted);
  };

  /**
   * @Name joinAppointmentRequest
   * @description This method is used to get session details like session token etc
   */
  const joinAppointmentRequest = useCallback((id: string) => {
    patchAppointment({ action: PatchAction.ARRIVE_FOR_APPOINTMENT }, { appointmentId: id })
      .then(({ data }) => {
        setTelesessionToken(data?.telesessionToken);
        setTelesessionId(data?.telesessionId);
        setSession(OT.initSession(OPENTOK_APIKEY, data?.telesessionId));
        setIsInitializing(false);
        setSelectedSession(FilterSessions[0].type);
      })
      .catch(err => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Can not join appointment',
          }),
        );
        history.push(
          `/provider/${meta?.userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
        );
      });
  }, []);

  /**
   * @Name getCareTeamList
   * @description This method is used to get care team list for the patient
   */
  const getCareTeamList = appt => {
    if (!appt?.participantId) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Please provide Member Id',
        }),
      );
    } else {
      getCareTeamMember({ userId: appt?.participantId })
        .then(careTeamListResponse => {
          if (careTeamListResponse?.status !== 200) {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Whoops ! Something went wrong . Please try later',
              }),
            );
          } else {
            setCareTeamListIds(careTeamListResponse?.data?.map(careTeam => careTeam?.connectionId) || []);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    }
  };

  /**
   * @Name useEffect
   * @description This method is used to get session details and set tele session token value
   *
   */

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 300,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 300,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  useEffect(() => {
    if (!appointment) {
      const item = appointmentsState?.find(app => app.appointmentId === appointmentId);
      if (item) {
        joinAppointmentRequest(appointmentId);

        dispatch(profileActionCreators.fetchDemographicDetails(item.participantId));

        const newItem = {
          ...item,
          participantGenderIdentity: demographicDetails?.genderIdentity,
        } as IAppointment;

        setAppointment(newItem);
        dispatch(profileActionCreators.fetchDomainTypes(item.participantId));
        getCareTeamList(item);
      } else if (!item && !isAdmin) {
        fetchAppointments2(profile?.providerId, providerSchedule?.timezone);
      }
    }
  }, [appointmentsState, appointment, appointmentId]);

  /**
   * @Name getEvaluationProgressList
   * @description This method is used to get Evaluation Progress List
   */
  const getEvaluationProgressList = () => {
    getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong. Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          const amount = responseList?.filter(
            evaluation => evaluation?.status === EVALUATION_STATUS.COMPLETED.toLocaleUpperCase(),
          )?.length;
          const sessionFilterObject = {
            type: 'evaluation',
            label: 'Evaluation',
            total: responseList?.length,
            amount,
          };
          // TODO : Please add Checklist count details here
          setSessionFilterList([
            { type: 'checklist', label: 'Checklist', total: Number(responseList?.length) + 4, amount },
            sessionFilterObject,
          ]);
          const notRejectedEvaluations = responseList.filter(
            evaluation => evaluation?.status !== EVALUATION_STATUS.REJECTED.toLocaleUpperCase(),
          );
          if (!initialEvaluation && notRejectedEvaluations.length === 1) {
            const inProgressEval = notRejectedEvaluations.find(
              evaluation => evaluation?.status !== EVALUATION_STATUS.COMPLETED.toLocaleUpperCase(),
            );
            if (inProgressEval) {
              setInitialEvaluation(inProgressEval);
            }
          }
          setShowInPerson(true);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
        history.back();
      });
  };

  /**
   * @Name useEffect
   * @description This method is used to get Evaluation List with progress detail of each evaluation
   */
  useEffect(() => {
    if (!appointmentId) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Please provide appointment Id',
        }),
      );
    } else {
      getEvaluationProgressList();
    }
  }, []);

  return (
    <>
      <EndSession
        onClose={toggleEndSessionConfirm}
        onEndSession={onEndSession}
        open={showEndSessionConfirm}
        endedReason={callEndedReason}
      />
      <FeedbackAppointment
        appointmentId={appointmentId}
        open={showFeedbackAppointment}
        onClose={onCloseFeedbackAppointment}
      />
      <Box className={classes.container}>
        {isWaiting && !isEndedSession ? (
          <WaitingRoomTopBar appointment={appointment} onOpenChat={() => setChatsDrawerOpen(true)} />
        ) : (
          <SessionFilters
            appointment={appointment}
            isSubmittedFeedback={isSubmittedFeedback}
            isEndedSession={isEndedSession}
            onEndSession={toggleEndSessionConfirm}
            showSignOff={showSignOff}
            setShowSignOff={setShowSignOff}
            isSessionStarted={startSession}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            sessionFilterList={sessionFilterList}
            inPersonSession={inPersonSession}
          />
        )}
        <Box className={classes.mainContent}>
          {isInitializing || !appointment ? (
            <Box display="flex" justifyContent="space-between" className={classes.waitingScreen}>
              <Box className={classes.boxLeft}>
                <WaitingHeader isCompleted={isEndedSession} appointment={appointment} />
                {/* <PriorityList isWaitingScreen appointment={appointment} /> */}
                {/* <Priorities hideNotes currentAppointment={appointment} /> */}
                <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                </Box>
              </Box>
              <AppointmentInfo
                isCompleted={isEndedSession}
                onEndSession={onEndSession}
                appointment={appointment}
                startInPersonSession={startInPersonCall}
                showInPersonSession={false}
              />
            </Box>
          ) : (
            <ProvideEvaluationHead>
              <VideoCallScreen
                isWaiting={isWaiting}
                setIsWaiting={setIsWaiting}
                onEndSession={toggleEndSessionConfirm}
                setShowSignOff={() => setShowSignOff(true)}
                isEndedSession={isEndedSession}
                setIsEndedSession={setIsEndedSession}
                chatsDrawerOpen={chatsDrawerOpen}
                setChatsDrawerOpen={setChatsDrawerOpen}
                selectedSession={selectedSession}
                onCloseSession={onCloseSession}
                onChangeView={setSelectedSession}
                appointment={appointment}
                telesessionToken={telesessionToken}
                telesessionId={telesessionId}
                onStartSession={onStartSession}
                sessionFilterList={sessionFilterList}
                careTeamListIds={careTeamListIds}
                getEvaluationProgressList={getEvaluationProgressList}
                startInPersonSession={startInPersonCall}
                isInPersonSessionCall={inPersonSession}
                showInPersonSession={showInPerson}
                initialEvaluation={initialEvaluation}
              />
            </ProvideEvaluationHead>
          )}
        </Box>
      </Box>
    </>
  );
};

export { Sessions };
