import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Text, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';

import { updateOperatingStates, updateOperatingStatesByAdmin } from 'services/member/member.service';
import { profileActionCreators } from 'redux/modules/profile';
import { getAuth } from 'redux/modules/auth/selectors';

import { AvailableStateSchema } from './AvailableState.schema';
import { AvailableStateForm } from './AvailableStateForm';
import { AvailableStateProps } from './AvailableState.types';
import { useStyles } from './AvailableState.styles';
import { stateSelector, availableStatesSelector } from '../../../../../redux/modules/state/selectors';
import { stateActionCreators } from '../../../../../redux/modules/state';

const AvailableState: React.FC<AvailableStateProps> = ({ isOpen, onClose, providerId, states }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { isAdmin } = useSelector(getAuth);
  const { payerGroups, states: availableStates } = useSelector(stateSelector);
  const { insurances: statesList } = useSelector(availableStatesSelector);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [payerList, setPayerList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (availableStates) {
      setPayerList(
        availableStates.map(state => {
          return {
            label: state.state.name,
            value: state.state.name,
          };
        }),
      );
    }
  }, [availableStates]);

  useEffect(() => {
    const initialValues = statesList?.map(payer => {
      return {
        state: payer.state,
        insurancesInState: payer?.insurances?.map(ins => ({
          title: ins.name,
          value: ins._id,
        })),
      };
    });
    setFormInitialValues(initialValues);
  }, [statesList]);
  const updateOperatingStatesAPI = async payload => {
    const finalStates = payload.states.filter(item => {
      return item.state !== '';
    });
    const insuranceObject = {
      insurances: finalStates.map(item => ({
        state: item.state,
        insurancesIds: item.insurancesInState.map(ins => ins.value),
      })),
    };
    try {
      setError(null);
      setIsSaving(true);
      const bodyRequest = {
        insurances: insuranceObject.insurances,
        operatingStates: payload.states.map(item => item.state),
        stateLimited: true,
      };
      if (isAdmin) {
        await updateOperatingStatesByAdmin(bodyRequest, { providerId });
        dispatch(profileActionCreators.fetchProviderDetails(providerId));
      } else {
        await updateOperatingStates(bodyRequest);
        dispatch(profileActionCreators.fetchProviderProfile());
      }
      onClose();
    } catch (err) {
      setError(err.data.errors?.[0]?.endUserMessage);
    }
    setIsSaving(false);
  };

  const { errors, values, handleSubmit, ...rest } = useFormik({
    initialValues: formInitialValues ? { states: formInitialValues } : { states: [] },
    validationSchema: AvailableStateSchema,
    onSubmit: updateOperatingStatesAPI,
  });

  const submitForm = async () => {
    await updateOperatingStatesAPI(values);
  };

  const onAddStateClick = () => {
    void rest.setFieldValue('states', [...values.states, { state: '', insurancesInState: [] }]);
  };

  const onUpdateState = idx => payload => {
    const newStates = values.states.map((item, index) => {
      if (idx === index) {
        return payload;
      }
      return item;
    });
    void rest.setFieldValue('states', newStates);
  };

  const onDeleteState = idx => () => {
    const newStates = values.states.filter((_, index) => idx !== index);
    void rest.setFieldValue('states', newStates);
  };
  useEffect(() => {
    dispatch(stateActionCreators.fetchStates());
  }, []);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ states: [] });
      rest.setErrors({});
    } else if (formInitialValues?.length) {
      const newStates = formInitialValues.map(item => ({
        state: item.state,
        insurancesInState: item.insurancesInState,
      }));
      void rest.setFieldValue('states', newStates);
    }
  }, [isOpen, states]);

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Available states</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={2}>
                <TextError errorMsg={error} />
              </Box>
            )}
            {values.states.map((item, index) => (
              <AvailableStateForm
                payersGroup={payerGroups}
                stateList={payerList}
                key={index}
                item={item}
                touched={rest.touched?.states?.[index] || {}}
                errorObj={errors.states?.[index] || {}}
                onDelete={onDeleteState(index)}
                onUpdate={onUpdateState(index)}
              />
            ))}
            <IconButton
              icon="plus"
              onClick={onAddStateClick}
              variant={iconBtnType.SECONDARY}
              className={classes.addStateBtn}
            >
              Add state
            </IconButton>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={submitForm} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AvailableState };
