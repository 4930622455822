import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

// components
import { Grid } from '@mui/material';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { TextField } from '@confidant-health/lib/ui/atoms/text-field';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

// state
import { getProfile } from 'redux/modules/profile/selectors';
import { IDemographicDetails, IPatient } from 'redux/modules/profile/types';
import { profileActionCreators } from 'redux/modules/profile';
import { stateSelector } from 'redux/modules/state/selectors';
import { showSnackbar } from '../../../../../redux/modules/snackbar';

// styles
import { useStyles } from '../../MemberDetail.styles';

type IContactInformationProps = {
  memberId: string;
};

const ContactInformation: FC<IContactInformationProps> = ({ memberId }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [states, setStates] = useState([]);
  const { selectedPatient = {} as IPatient, demographicDetails = {} as IDemographicDetails } =
    useSelector(getProfile);

  const { states: statesList } = useSelector(stateSelector);
  const classes = useStyles({});

  const [formData, setFormData] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
  });

  const dispatch = useDispatch();

  const getStatesList = () => {
    const orderedStates = statesList.map(({ state }) => ({ label: state.name, value: state.name }));
    setStates(orderedStates);
  };

  useEffect(() => {
    setFormData({
      address1: demographicDetails.address1 || '',
      address2: demographicDetails.address2 || '',
      city: demographicDetails.city || '',
      state: demographicDetails.state || '',
      zip: demographicDetails.zip || '',
      phone: demographicDetails.mobilePhone || '',
      email: demographicDetails.email || '',
    });
  }, [selectedPatient]);

  useEffect(() => {
    if (!openDrawer) {
      setFormData({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
      });
    } else {
      void getStatesList();
      setFormData({
        address1: demographicDetails.address1 || '',
        address2: demographicDetails.address2 || '',
        city: demographicDetails.city || '',
        state: demographicDetails.state || '',
        zip: demographicDetails.zip || '',
        phone: demographicDetails.mobilePhone || '',
        email: demographicDetails.email || '',
      });
    }
  }, [openDrawer, demographicDetails]);

  const onChangeField = (field: string) => (e: any) => {
    let value = e.target?.value;
    if (field === 'phone') {
      value = e;
    }
    setFormData(old => ({
      ...old,
      [field]: value,
    }));
  };

  const formatContactNumber = () => {
    if (demographicDetails.mobilePhone) {
      const last10Digits = demographicDetails.mobilePhone.slice(-10);
      // Format the number as (XXX) XXX-XXXX
      const formattedNumber = `(${last10Digits.slice(0, 3)}) ${last10Digits.slice(3, 6)}-${last10Digits.slice(
        6,
      )}`;

      return formattedNumber;
    }
    return demographicDetails.mobilePhone;
  };
  // const updateContactInfo = async formData => {
  //   payload = {
  //     ...formData,
  //     address: formData.address,
  //     city: formData.city,
  //     email: formData.email,
  //     phone: formData.phone,
  //     state: formData.email,
  //     zip: formData.zip,
  //   };
  // };
  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => setOpenDrawer(false);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: { ...formData },
    enableReinitialize: true,
    onSubmit: () => {
      const schema = yup.string().email();
      const result = schema.isValidSync(values.email);
      if (result) {
        dispatch(
          profileActionCreators.updateContactInfo({
            contactInfo: {
              ...formData,
              email: values.email,
            },
            memberId,
          }),
        );
        handleCloseDrawer();
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Invalid email provided',
          }),
        );
      }
    },
  });
  // const handleOnSubmit = () => {
  //   // void updateContactInfo(formData);
  //   dispatch(
  //     profileActionCreators.updateContactInfo({
  //       contactInfo: {
  //         ...formData,
  //         address1: formData.address1,
  //         address2: formData.address2,
  //         city: formData.city,
  //         email: formData.email,
  //         phone: formData.phone,
  //         state: formData.state,
  //         zip: formData.zip,
  //       },
  //       memberId,
  //     }),
  //   );
  //   handleCloseDrawer();
  // };

  const renderInfo = (label: string, text: string) => {
    return (
      <Grid item sm={1} xs={3} className={classes.preLineText}>
        <TextField label={label} text={text || '-'} />
      </Grid>
    );
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Contact information
        </Heading>
        <Link onClick={handleOpenDrawer}>Edit contact information</Link>
      </div>
      <Grid className={classes.cardContentSection} container columns={3} rowSpacing="32px" spacing={2}>
        {renderInfo('Phone', formatContactNumber())}
        {renderInfo(
          'Address',
          demographicDetails.address1 && `${demographicDetails.address1}\n${demographicDetails.address2}`,
        )}
        {renderInfo('State', demographicDetails.state)}
        {renderInfo('Email', demographicDetails.email)}
        {renderInfo('City', demographicDetails.city)}
        {renderInfo('Zip', demographicDetails.zip)}

        {/* {renderInfo('Phone', selectedPatient.phone)}
        {renderInfo('Address', selectedPatient.address?.street)}
        {renderInfo('State', selectedPatient.address?.state)}
        {renderInfo('Email', selectedPatient.email)}
        {renderInfo('City', selectedPatient.address?.city)}
        {renderInfo('Zip', selectedPatient.address?.zipcode)} */}
      </Grid>
      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={handleCloseDrawer}
        onSubmit={handleSubmit}
        title="Edit contact information"
        submitBtnTitle="Update"
      >
        <form onSubmit={handleSubmit}>
          <div className={classes.inputsContainer}>
            <div className={classes.inputWrapper}>
              <label htmlFor="phone">
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  Phone
                </Text>
              </label>
              <Input
                value={formData.phone}
                name="phone"
                size={inputSize.M}
                onChange={onChangeField('phone')}
                variant={inputType.PHONE}
                placeholder="Enter your phone"
              />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="email">
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  Email
                </Text>
              </label>
              <Input
                value={values.email}
                startAdornment={<Icons glyph="email-outlined" color={colors.neutral500} />}
                name="email"
                onChange={handleChange}
                size={inputSize.M}
                placeholder="Enter your email"
              />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="address1">
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  Address line 1
                </Text>
              </label>
              <Input
                name="address1"
                value={formData.address1 || ''}
                onChange={onChangeField('address1')}
                size={inputSize.M}
                placeholder="Enter your address line 1"
              />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="address2">
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  Address line 2
                </Text>
              </label>
              <Input
                name="address2"
                value={formData.address2 || ''}
                onChange={onChangeField('address2')}
                size={inputSize.M}
                placeholder="Enter your address line 2"
              />
            </div>
            <div className={classes.horizontalInputsContainer}>
              <div className={classes.inputWrapper}>
                <label htmlFor="city">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    City
                  </Text>
                </label>
                <Input
                  value={formData.city || ''}
                  id="city"
                  onChange={onChangeField('city')}
                  size={inputSize.M}
                  placeholder="Enter your city"
                />
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="state">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    State
                  </Text>
                </label>
                <Select
                  value={formData.state}
                  name="state"
                  variant={selectType.SECONDARY}
                  options={states}
                  onChange={onChangeField('state')}
                  // defaultValue={formData.state}
                />
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="zip">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Zip
                  </Text>
                </label>
                <Input
                  value={formData.zip || ''}
                  id="zip"
                  onChange={onChangeField('zip')}
                  size={inputSize.M}
                  placeholder="Enter your zip"
                />
              </div>
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default ContactInformation;
