/* eslint-disable jsx-a11y/aria-role */
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Box, Grid } from '@mui/material';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Tooltip } from '@confidant-health/lib/ui/atoms/tooltip';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusDone,
  AppointmentStatusText,
  SignOffStatus,
} from '@confidant-health/lib/constants/CommonConstants';
import { getUserTimeZone } from 'utils/dayjs';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import {
  EVALUATION_STATUS_V2,
  evaluationStatusStyleV2,
} from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IAssignedEvaluation } from '../../../../../redux/modules/conversation/types';
import { IAppointmentCardProps } from './AppointmentCardv2.types';
import { useStyles } from './Appointments.styles';
import history from '../../../../../utils/history';
import { getAppointmentEvaluationProgress } from '../../../../../services/conversation/conversation.service';
import AssignedEvaluationDrawer from '../../../priorities/appointments/AssignedEvaluationDrawer';
import EvaluationDetail from '../../../priorities/appointments/evaluation-detail';
import { getProfile } from '../../../../../redux/modules/profile/selectors';

// type Props = {
//   isPast?: boolean;
//   onSelectAssignedEvaluation?: (evaluation: any) => void;
//   assignedEvaluations?: IAssignedEvaluation[];
// };

const AppointmentCardv2: FC<IAppointmentCardProps> = ({
  appointment,
  onViewClick,
  onStartSessionClick,
  onRescheduleClick,
  selectAppointment,
  appointmentSection,
  assignedEvaluations,
  viewDetail,
  isRecurring,
}) => {
  const classes = useStyles();
  const { profile } = useSelector(getProfile);
  const [selectedEvaluation, setSelectedEvaluation] = useState<IAssignedEvaluation>(null);
  const providerSchedule = useSelector(selectProviderSchedule);
  const [evaluations, setEvaluations] = useState<IAssignedEvaluation[]>(assignedEvaluations || []);
  const [showAssignedEvaluations, setShowAssignedEvaluations] = useState(false);
  const { appointmentId } = appointment;

  useEffect(() => {
    getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          setEvaluations(responseList);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  }, [appointmentId]);

  const rescheduleAppointment = () => {
    selectAppointment(appointment);
    onRescheduleClick();
  };

  const viewAppointmentDetails = () => {
    onViewClick(appointment);
  };

  const onAcceptAppointmentClick = () => {
    onStartSessionClick(appointment);
  };

  const onGoToChat = () => {
    history.push(`/provider/chats?userId=${appointment?.patientId}&type=members`);
  };

  const handleStartSessionClick = () => {
    onStartSessionClick(appointment);
  };

  const onSelectAssignedEvaluation = (evaluation: IAssignedEvaluation) => {
    setSelectedEvaluation(evaluation);
  };

  const appointmentDate = dayjs(appointment.startTime);
  const isToday = appointmentDate.isSame(dayjs(), 'day');
  const isDone = AppointmentStatusDone.includes(appointment.status);

  const isWaitingSignOff =
    appointment.requireSupervisorSignOff &&
    !!appointment.signOffStatus &&
    appointment.signOffStatus !== SignOffStatus.APPROVED;
  let finalStatus =
    // eslint-disable-next-line no-nested-ternary
    !isDone && appointment.status !== AppointmentStatus.BOOKED && isWaitingSignOff
      ? appointment.signOffStatus === SignOffStatus.REVIEW
        ? 'WAITING_REVIEW'
        : 'SIGN_OFF'
      : appointment.status;

  if (appointment.signOffStatus === SignOffStatus.DRAFTED) {
    finalStatus = 'SIGN_OFF';
  }
  const { status, signOffStatus, patientStatus, practitionerStatus } = appointment || {};
  let statusText = AppointmentStatusText[status];
  if (status === AppointmentStatus.FULFILLED && signOffStatus === SignOffStatus.DRAFTED) {
    statusText = 'Waiting for Sign off';
  }
  if (status === AppointmentStatus.PROPOSED || status === AppointmentStatus.PENDING) {
    if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Requested';
    } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Pending';
    }
  }
  if (status === AppointmentStatus.BOOKED) {
    statusText = isToday ? 'Today' : 'Scheduled';
  }
  const start = dayjs(appointment.startTime)
    .tz(providerSchedule?.timezone || getUserTimeZone())
    .format('h:mm a');
  const end = dayjs(appointment.endTime)
    .tz(providerSchedule?.timezone || getUserTimeZone())
    .format('h:mm a');

  return (
    <Box className={classes.apptCardContainer}>
      <Box className={classes.apptCard}>
        <Box className={classes.apptCardBody} onClick={() => viewDetail(appointment)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Box className={clsx(classes.text16, classes.row)}>
              {dayjs(appointment.startTime).format('MM/DD/YYYY')}
              {isRecurring && <Icons glyph="recurring" color={colors.secondary500} />}
            </Box>
            <Tooltip title={`Time: ${start} - ${end}`}>
              <Box className={classes.text16Second}>{`${start} - ${end}`}</Box>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <Avatar
              size={40}
              variant={avatarType.CIRCLE}
              src={appointment.participantImage}
              name={
                appointment?.patientFirstName && appointment?.patientLastName
                  ? `${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`
                  : appointment?.participantName ?? 'No Name'
              }
            />
            <Box>
              <Text className={classes.userNameText} weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                {appointment?.patientFirstName && appointment?.patientLastName
                  ? `${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`
                  : appointment?.participantName ?? 'No Name'}
              </Text>
              <Text className={classes.userInfoText} weight={fontWeight.MEDIUM} level={textLevel.S}>
                {appointment?.patientUuid}
              </Text>
            </Box>
          </Box>
          <Badge
            style={
              appointment.signOffStatus === 'REVIEW' &&
              appointment.status === 'FULFILLED' &&
              appointmentSection !== 'past'
                ? AppointmentStatusBagde.SIGN_OFF
                : AppointmentStatusBagde[finalStatus]
            }
            variant={badgeType.FILLED}
            className={classes.badge}
          >
            {(appointment.signOffStatus === 'REVIEW' &&
              appointment.status === 'FULFILLED' &&
              appointmentSection === 'pending') ||
            (profile.signOffRole === 'SUPERVISOR' &&
              appointment.signOffStatus === 'REVIEW' &&
              appointment.status === 'FULFILLED') ? (
              'Waiting for Supervisor Sign Off'
            ) : statusText?.toLowerCase()?.includes('cancel') ? (
              <span className={classes.cancelledStatusText}>{statusText}</span>
            ) : (
              statusText
            )}
          </Badge>
          <Tooltip title={appointment?.serviceName}>
            <Box className={classes.text16}>{appointment?.serviceName}</Box>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flex: 0,
              justifyContent: 'flex-end',
              gap: '12px',
              flexDirection: 'column',
            }}
          >
            {patientStatus !== AppointmentStatus.NEEDS_ACTION &&
              appointment.status !== AppointmentStatus.FULFILLED &&
              appointment.status !== AppointmentStatus.CANCELLED &&
              !appointment.instantAppointment && (
                <IconButton
                  variant={iconBtnType.OUTLINE}
                  className={clsx(classes.btnReschedule)}
                  onClick={rescheduleAppointment}
                  icon="calendar-today-outlined"
                >
                  Reschedule
                </IconButton>
              )}
            {(appointment.status === AppointmentStatus.PROPOSED ||
              appointment.status === AppointmentStatus.PENDING) && (
              <IconButton
                variant={
                  patientStatus === AppointmentStatus.NEEDS_ACTION ? iconBtnType.OUTLINE : iconBtnType.PRIMARY
                }
                className={
                  patientStatus === AppointmentStatus.NEEDS_ACTION ? clsx(classes.btnReschedule) : classes.btn
                }
                icon={
                  practitionerStatus === AppointmentStatus.NEEDS_ACTION
                    ? 'checkmark'
                    : patientStatus === AppointmentStatus.NEEDS_ACTION
                    ? 'chat'
                    : null
                }
                onClick={
                  practitionerStatus === AppointmentStatus.NEEDS_ACTION
                    ? onAcceptAppointmentClick
                    : patientStatus === AppointmentStatus.NEEDS_ACTION
                    ? onGoToChat
                    : handleStartSessionClick
                }
              >
                {practitionerStatus === AppointmentStatus.NEEDS_ACTION
                  ? 'Accept appointment'
                  : patientStatus === AppointmentStatus.NEEDS_ACTION
                  ? 'Go to chat'
                  : 'Start session'}
              </IconButton>
            )}
            {appointment.status === AppointmentStatus.BOOKED &&
              (appointmentDate.isSame(dayjs(), 'day') || appointmentDate.isAfter(dayjs(), 'day')) && (
                <IconButton
                  variant={iconBtnType.PRIMARY}
                  className={classes.btn}
                  icon={null}
                  onClick={handleStartSessionClick}
                >
                  Start session
                </IconButton>
              )}
            {status === AppointmentStatus.FULFILLED && signOffStatus === SignOffStatus.DRAFTED && (
              <IconButton
                variant={iconBtnType.OUTLINE}
                className={clsx(classes.btnReschedule)}
                onClick={viewAppointmentDetails}
              >
                {signOffStatus === SignOffStatus.DRAFTED ? 'Sign off on Note' : 'View Details'}
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      {evaluations.map((evaluation, index) => {
        const evaluationStatus =
          evaluation.status === 'NOT_STARTED'
            ? 'Assigned'
            : evaluation.status === 'IN_PROGRESS'
            ? 'In progress'
            : evaluation.status === 'INCOMPLETE'
            ? 'Incomplete'
            : EVALUATION_STATUS_V2[evaluation.status];
        const statusBadgeStyle =
          evaluation.status === 'NOT_STARTED'
            ? badgeStyle.INACTIVE
            : evaluation.status === 'IN_PROGRESS' || evaluation.status === 'INCOMPLETE'
            ? badgeStyle.PRIMARY
            : evaluationStatusStyleV2[evaluation.status];
        const { totalCb, totalExercises, totalRequiredCb, forProviderCount, unanswered } =
          evaluation?.evaluationStats ?? {
            totalCb: 0,
            totalExercises: 0,
            totalRequiredCb: 0,
            forProviderCount: 0,
            unanswered: 0,
          };
        return (
          <Box
            key={index}
            className={classes.evaluation}
            onClick={() => onSelectAssignedEvaluation(evaluation)}
          >
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'inherit',
                  }}
                >
                  <Box className={classes.text14}>{evaluation.name}</Box>
                  <Badge className={classes.badge} variant={badgeType.OUTLINED} style={statusBadgeStyle}>
                    {evaluationStatus}
                  </Badge>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'flex-end',
                    width: '100%',
                    flexFlow: 'wrap',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box className={classes.text14}>All</Box>
                    <Box>
                      <Badge
                        className={classes.badge}
                        variant={badgeType.OUTLINED}
                        style={badgeStyle.INACTIVE}
                      >
                        {totalCb}
                      </Badge>
                    </Box>
                  </Box>
                  {totalRequiredCb > 0 && (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Box className={classes.text14}>Required</Box>
                      <Box>
                        <Badge
                          className={classes.badge}
                          variant={badgeType.OUTLINED}
                          style={badgeStyle.INACTIVE}
                        >
                          {totalRequiredCb}
                        </Badge>
                      </Box>
                    </Box>
                  )}
                  {unanswered > 0 &&
                    (evaluation.status === 'COMPLETED' || evaluation.status === 'IN_PROGRESS') && (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Box className={classes.text14}>Unanswered</Box>
                        <Box>
                          <Badge
                            className={classes.badge}
                            variant={badgeType.OUTLINED}
                            style={badgeStyle.INACTIVE}
                          >
                            {unanswered}
                          </Badge>
                        </Box>
                      </Box>
                    )}
                  {forProviderCount > 0 && (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Box className={classes.text14}>For provider</Box>
                      <Box>
                        <Badge
                          className={classes.badge}
                          variant={badgeType.OUTLINED}
                          style={badgeStyle.INACTIVE}
                        >
                          {forProviderCount}
                        </Badge>
                      </Box>
                    </Box>
                  )}
                  {totalExercises > 0 && (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Box className={classes.text14}>Exercises</Box>
                      <Box>
                        <Badge
                          className={classes.badge}
                          variant={badgeType.OUTLINED}
                          style={badgeStyle.INACTIVE}
                        >
                          {totalExercises}
                        </Badge>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      })}
      {showAssignedEvaluations && (
        <AssignedEvaluationDrawer
          onEvaluationSelected={onSelectAssignedEvaluation}
          evaluations={evaluations}
          open={showAssignedEvaluations}
          past={false}
          onClose={() => setShowAssignedEvaluations(false)}
        />
      )}
      {selectedEvaluation && (
        <EvaluationDetail
          memberId={appointment.patientId}
          isOpen={!!selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
          evaluation={selectedEvaluation}
          appointmentId={appointmentId}
          isView={false}
        />
      )}
    </Box>
  );
};

export default AppointmentCardv2;
