import { sortDirection } from '@confidant-health/lib/ui/organisms/table/grid';
import { INote } from '@confidant-health/lib/ui/templates/note-card';
import { AxiosResponse } from 'axios';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { IInsuranceRequest } from 'pages/provider/member-detail/components/insurance-information/index.types';
import { IService } from 'redux/modules/appointment/types';
import { IGetConnectionByStatusQuery, IGetPaginatedConnectionsQuery } from 'redux/modules/profile/types';
import { baseRequest } from 'utils/HttpClient';

export const downloadMemberList = (payload: any, requestBody: any): Promise<AxiosResponse> => {
  return baseRequest(
    ApiEndpoints.DOWNLOAD_PATIENTS_BY_USER,
    requestBody || {},
    null,
    payload,
    true,
    true,
    true,
  );
};
export const getProviderProfile = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.PROVIDER_PROFILE, {}, null, null, true);
};

export const getProviderProfileById = (pathParams: { providerId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.PROVIDER_PROFILE_BY_ID, null, pathParams);
};

export const getProviderAll = (payload: any, requestBody: any): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.FETCH_ALL_PROVIDERS, requestBody || {}, null, payload);
};

export const getPatientsList = (
  payload: {
    searchQuery;
    pageNumber;
    pageSize;
    orderBy;
    sortBy;
  },
  requestBody: any,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PATIENTS_BY_USER, requestBody || {}, null, payload);
};

export const getDemographicDetails = (pathParams: { connectionId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DEMOGRAPHIC_DETAIL, null, pathParams);
};

export const updateDemographicDetails = (
  requestBody: Record<string, unknown>,
  pathParams: { connectionId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_DEMOGRAPHIC_DETAIL, requestBody, pathParams, null, true);
};

export const addBalanceInWallet = (bodyRequest: { amount; connectionId }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_WALLET_BALANCE, bodyRequest, null, null, true);
};

export const getWalletBalance = (connectionId): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_WALLET, null, { connectionId }, null, true);
};

export const getUserGroups = (pathParams: { userId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.USER_GROUPS, null, pathParams);
};

export const getAdminProfile = (queryParams?: { userId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROFILE, {}, null, queryParams || null, true);
};

export const getMemberNotes = (
  pathParams: { userId: string },
  queryParams?: {
    startDate: string;
    endDate: string;
  },
): Promise<AxiosResponse> => {
  return baseRequest(
    ApiEndpoints.GET_MEMBER_NOTES,
    null,
    pathParams,
    queryParams?.startDate ? queryParams : null,
  );
};
export const getNotesWithFilters = (queryParams: any, requestBody: any): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_NOTES, requestBody, null, queryParams);
};
export const addMemberNotes = (requestBody: INote): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_MEMBER_NOTES, requestBody);
};

export const updateMemberNotes = (requestBody: INote): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_MEMBER_NOTES, requestBody);
};
export const getALLContactNotesCount = (
  pathParams,
  queryParams: { noteTypes: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CONTACT_NOTES_COUNT, null, pathParams, queryParams);
};
export const getContactNotesCountByPatient = (pathParams: { patientId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CONTACT_NOTES_COUNT_BY_PATIENT, null, pathParams);
};
export const getConnections = (queryParams: { userId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CONNECTIONS, {}, null, queryParams, true);
};

export const getPaginatedConnections = (
  queryParams: IGetPaginatedConnectionsQuery,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PAGINATED_CONNECTIONS, {}, null, queryParams, true);
};

export const getConnectionsByStatus = (queryParams: IGetConnectionByStatusQuery): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CONNECTIONS_BY_STATUS, {}, null, queryParams, true);
};

type PatientQuery = {
  patientId: string;
  searchQuery?: string;
};
export const getDomainTypes = (queryParams: PatientQuery): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_TYPES, null, null, queryParams);
};

export const getPatientHistory = (queryParams: PatientQuery): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PATIENT_HISTORY, null, null, queryParams);
};

export const getDomainLookups = (queryParams: PatientQuery): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_LOOKUP, null, null, queryParams);
};

export const getDomainElements = (pathParams: { domainTypeId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENTS, null, pathParams);
};

export const getDomainGroup = (pathParams: { domainTypeId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_GROUPS, null, pathParams);
};

export const getDomainElementDetail = (queryParams: {
  searchQuery?: string;
  associatedTagId: string;
  patientId: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENT, null, null, queryParams);
};

export type NewAssociatedTagBody = {
  patientId: string;
  tagItems: {
    assignedAt?: string;
    assignedBy: string;
    domainElementId: string;
    selectedIct10code?: string;
    metaData: {
      interferenceInLife?: boolean;
      notes: string;
      selectedImportance: string;
      // specification?: any;
      historyInfo?: {
        allergies: string[];
        criminalJusticeInvolvement: boolean;
        familyMedicationConditions: string[];
        familyMentalHealthConditions: string[];
        genderIdentity: string;
        genderPronoun: string;
        hasSupportNetwork: boolean;
        medicalConditionsCurrentlyTreatedFor: string[];
        mentalHealthConditionsCurrentlyTreatedFor: string[];
        preferredPharmacy: string;
        previousOverDose: boolean;
        previousProblemsWithMedication: string;
        previousSuicideAttempt: boolean;
        previouslyDiagnosed: boolean;
        previouslyDiagnosedMedicalConditions: string[];
        previouslyDiagnosedMentalHealthConditions: string[];
        previouslyHospitalizedForPsychiatricCare: boolean;
        previouslyReceivedSubstanceUseTreatment: boolean;
        previouslySeenProvider: boolean;
        sexAssigned: string;
      };
      rxDrugInfo?: {
        dose?: number;
        frequency?: string;
        duration?: string;
        refillable?: boolean;
        refillTimes?: string;
        confidantProviderId?: string;
        confidantProviderName?: string;
        doseFrequency?: number;
        doseUnit?: string;
        prescribingProvider?: string;
        providerName?: string;
        supply?: number;
        supplyUnit?: string;
        symptomsBeingTreated?: string[];
        dosage?: string;
      };
      specification?: any;
      // specification?: {
      //   relatedToMedicalCondition: any[];
      //   relatedToMedication: string[];
      //   relatedToSubstanceUse: string[];
      //   relatedToWithdrawal: string[];
      // };
      substanceUse?: {
        currentFrequencyOfUse: string;
        howLongUsingThisLevel: string;
        lastUse: string;
        methodOfUse: string;
        unitOfUse: string;
        dose?: number;
        doseUnit?: string;
        refillable?: boolean;
      };
    };
  }[];
};
export const createNewAssociatedTag = (bodyRequest: NewAssociatedTagBody): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.CREATE_ASSOCIATED_TAG, bodyRequest, null);
};

export const updateAssociatedTag = (
  bodyRequest: { importanceLevel: string; notes: string },
  queryParams: { associatedTagId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_ASSOCIATED_TAG, bodyRequest, null, queryParams);
};

export const getLevelOfEngagements = (
  filters: {
    searchQuery?: string;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: sortDirection;
  } = {},
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_LEVEL_ENGAGEMENTS, null, null, filters);
};

export const addLevelOfEngagement = (payload: {
  name: string;
  description: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_LEVEL_ENGAGEMENT, payload);
};

export const updateLevelOfEngagement = (
  id: string,
  payload: { name?: string; description?: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_LEVEL_ENGAGEMENT, payload, { id });
};

export const deleteLevelOfEngagement = (id): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_LEVEL_ENGAGEMENT, null, { id });
};

export const updateLevelOfEngagementUser = (pathParams: {
  connectionId: string;
  engagementLevelId: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_LEVEL_ENGAGEMENT_USER, null, pathParams);
};

export const updateChatStatusUser = (
  pathParams: { connectionId: string },
  queryParams: { inActiveChat: boolean },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_CHAT_STATUS_USER, null, pathParams, queryParams);
};

export const setCareTeamMemberData = (
  bodyRequest: { members: any },
  pathParams: { userId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.SET_CARE_TEAM_MEMBER_DATA, bodyRequest, pathParams);
};

export const getCareTeamMember = (
  pathParams: { userId: string },
  queryParams?: { scheduleAppointment?: boolean },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CARE_TEAM_MEMBER, null, pathParams, {
    scheduleAppointment: queryParams?.scheduleAppointment || false,
  });
};

export const getMatchmakers = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_MATCHMAKERS, null, null);
};
export const updateContactInfo = (bodyRequest, pathParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_CONTACT_INFO, bodyRequest, pathParams);
};
export const updateEmergencyInfo = (bodyRequest, pathParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_EMERGENCY_INFO, bodyRequest, pathParams);
};

export const suggestConnection = (bodyRequest: {
  suggestedFirstUser: string;
  suggestedSecondUser?: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.SUGGEST_CONNECTION_REQUEST, bodyRequest, null);
};

export const getConversations = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CONVERSATIONS, null, null);
};

type AssignChatbotPayload = {
  conversationId: string;
  organizationId: string;
  patientUserId: string;
  priority?: string;
};
export const assignChatbot = (payload: AssignChatbotPayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ASSIGN_CONVERSATION, payload, null);
};

type PROFILE_PAYLOAD = {
  affiliationImage: string;
  affiliationName: string[];
  affiliationPlace: string[];
  approach: string;
  certifications: string[];
  credentials: string[];
  educationDescription: string;
  educationEndDate: string;
  educationImage: string;
  educationName: string[];
  educationPlace: string[];
  educationStartDate: string;
  employmentEndDate: string;
  employmentImage: string;
  employmentName: string[];
  employmentPlace: string[];
  employmentStartDate: string;
  firstName: string;
  fullName: string;
  lastName: string;
  providerRole: string;
  philosophy?: string;
  backstory?: string;
  profilePicture?: string;
  providerId?: string;
  providerImages?: string[];
  rating?: number;
  requireSupervisorSignOff?: boolean;
  reviews?: number;
  signOffRole?: string;
  training?: string;
};
export const updateProvider = (providerId: string, payload: PROFILE_PAYLOAD): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_PROFILE, payload, { providerId });
};

export const updateOperatingStates = (payload: {
  operatingStates: string[];
  stateLimited: boolean;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_OPERATING_STATES, payload);
};

export const updateOperatingStatesByAdmin = (
  payload: {
    operatingStates: string[];
    stateLimited: boolean;
  },
  pathParams: { providerId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_OPERATING_STATES, payload, pathParams);
};

export const updateService = (id: string, service: IService): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_SERVICE, service, { id }, null);
};

export const addNewService = (service: IService): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_NEW_SERVICE, service, null);
};

export const deleteService = (serviceId: string, providerId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_SERVICE, null, { serviceId }, { providerId });
};

export const getServiceTypes = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_SESSION_TYPES, null, null);
};

export const getGroups = (queryParams: { isPublic: boolean }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_ALL_GROUPS, null, null, queryParams);
};

export const getDemographicMetadata = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DEMOGRAPHIC_METADATA);
};

export const getMemberInsuranceInfo = (pathParams: { userId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_INSURANCE_INFO, null, pathParams);
};

// export const getPatientDataDomainElement = (pathParams: { patientId: string }): Promise<AxiosResponse> => {
//   return baseRequest(ApiEndpoints.GET_PATIENT_DOMAIN_ELEMENT, null, pathParams);
// };
export const updateMemberInsuranceInfo = (
  payload: Partial<IInsuranceRequest>,
  userId: { userId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_INSURANCE_INFO, payload, userId);
};

export const addMemberInsuranceInfo = (payload: Partial<IInsuranceRequest>): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_INSURANCE_INFO, payload, null);
};

export const getServicesOnServiceType = (payload: { providerIdList: string[] }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.FETCH_SERVICES_ON_SERVICE_TYPE, payload);
};
export const getServicesOnServiceName = (payload: { providerIdList: string[] }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.FETCH_SERVICES_ON_SERVICES_NAME, payload);
};
export const inviteMember = (pathParams: { memberId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.INVITE_MEMBER, null, pathParams);
};

export type ReferralPartnerType = {
  id?: string;
  name: string;
  patientDetail: boolean;
  patientDetailAppointment: boolean;
  patientDetailDiagnosis: boolean;
  patientDetailEvaluation: boolean;
  patientDetailMedication: boolean;
  patientDetailPhq9Gad7: boolean;
  patientList: boolean;
  reportList: boolean;
  [key: string]: any;
};
export const getReferralPartners = (queryParams: {
  pageNumber: number;
  pageSize: number;
  search: string;
}) => {
  return baseRequest(ApiEndpoints.GET_REFERRAL_PARTNERS, null, null, queryParams);
};

export const createReferralPartner = (requestBody: ReferralPartnerType) => {
  return baseRequest(ApiEndpoints.CREATE_REFERRAL_PARTNER, requestBody);
};

export const updateReferralPartner = (
  requestBody: ReferralPartnerType,
  pathParams: { referralPartnerId: string },
) => {
  return baseRequest(ApiEndpoints.UPDATE_REFERRAL_PARTNER, requestBody, pathParams);
};

export const getAllReferredPatientsByPartnerId = (
  queryParams: {
    pageNumber: number;
    pageSize: number;
  },
  pathParams: {
    refPartnerId: string;
  },
) => {
  return baseRequest(ApiEndpoints.GET_ALL_REFERRED_PATIENTS_BY_PARTNER, null, pathParams, queryParams);
};

export const getRefferedPartnerMemberStats = (pathParams: { referralPartnerId: string }) => {
  return baseRequest(ApiEndpoints.GET_REFERRED_PARTNER_MEMBER_STATS, null, pathParams);
};

export const validateInsuranceInfo = (userAccountId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.VALIDATE_INSURANCE_INFO, null, { userAccountId });
};

export const getMemberCardTransactions = (
  pathParams: { memberId: string },
  queryParams: { appointmentMeta?: boolean; pageNumber?: number; pageSize?: number },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PATIENT_CARD_TRANSACTIONS, null, pathParams, queryParams);
};

export const updateMemberIdCard = (requestBody: {
  idCardBack: string;
  idCardFront: string;
  memberId: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_MEMBER_ID_CARD, requestBody);
};
