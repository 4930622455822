// types
import { EntityState } from '@reduxjs/toolkit';
import { IDomainType } from 'redux/modules/profile/types';
import { DctType } from 'redux/modules/reporting/types';
import { DCT_STATUS } from 'constants/CommonConstants';

export interface IDCT1 {
  assignmentCount: number;
  contentBlocksCount: number;
  dctId: string;
  dctType: DctType;
  lastUpdated: string;
  name: string;
  scorable: boolean;
  status: DCT_STATUS;
  usedInConversations: Array<string>;
  version: number;
  description?: string;
}

export interface IDCT {
  completed: boolean;
  dctId: string;
  dctName: string;
  finalScore: number;
  responses: {
    cbId: string;
    answer: string;
    question: string;
  }[];
  scorable: boolean;
}

export interface IChatbot {
  assignedAt: string;
  botImage: string;
  chatbotName: string;
  completed: boolean;
  completedAt: string;
  contactId: string;
  chatbotVersion?: string;
  conversationId: string;
  dcts: IDCT[];
  percentage: number;
  totalCompletions: number;
  latestContextId: string;
  lastModified: string;
}

export interface IChatbotContact {
  dcts: IDCT[];
  name: 'string';
  status: 'string';
  version: number;
  percentage: number;
  totalCompletions: number;
}

export interface IChatbotAttemptHistory {
  attemptDetails: {
    attemptTime: string;
    contextId: string;
    progress: number;
    status: string;
    providerDesignation?: string;
    providerId?: string;
    providerName?: string;
    providerProfileImage?: string;
  }[];
  date: string;
}

export interface IGiftedMessage {
  _id: number;
  parentMessageId?: number;
  mentionedUsers?: any[];
  prevMsg?: any;
  message: {
    text: string;
  };
  nickName: string;
  createdAt: number;
  type: string;
  fileMeta: {
    url: string;
    type: string;
    loading: boolean;
    progress: number;
  };
  system: boolean;
  user: {
    userId: number;
    name: string;
    avatar: string;
  };
}

export interface AutomationRuleState {
  isLoading: boolean;
  isSaving: boolean;
  errorMsg: string;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  automationRules: any[];
}

export interface AutomationRuleCount {
  isLoading: boolean;
  errorMsg: string;
  counts: {
    appointmentAutomations: number;
    chatBotAutomations: number;
    educationAutomations: number;
    groupAutomations: number;
    paymentAutomations: number;
    planItemAutomations: number;
    profileElementAutomations: number;
  };
}

export interface AIJob {
  jobId: string;
  name: string;
  model: string;
  temperature: number;
  maxTokens: number;
  active: boolean;
  prompt: string;
}

export interface ConversationState {
  isLoading: boolean;
  isLoadingMoreMessages: boolean;
  isLoadingEarlier: boolean;
  channelUrl: null;
  chatbots: IChatbot[];
  isCBContactsLoading: boolean;
  chatbotContacts: IChatbotContact;
  isCBAttemptHistoryLoading: boolean;
  chatbotAttemptHistory: IChatbotAttemptHistory[];
  chatbotcompleteHistory: IChatbotAttemptHistory[];
  sendbirdStatus: number;
  connectionStatus: number;
  recentMessageSend: any[];
  channelMembers: any[];
  messages: any[];
  sendingMessage: boolean;
  allTodos: any;
  loadEarlier: boolean;
  errorMsg: string;
  dct: {
    isLoading: boolean;
    dcts: Array<IDCT1>;
    totalRecords: number;
    errorMsg: string;
  };
  sessionType: SessionTypeState;
  planItem: PlanItemState;
  profileElements: ProfileElementsState;
  assignedPlanItems: EntityState<{ userId: string; items: Array<IAssignedPlanItem> }> & {
    isLoading: boolean;
    isSaving?: boolean;
    errorMsg: string;
  };
  conversation: ConversationsListState;
  evaluation: EvaluationsListState;
  revampType: RevampTypesListState;
  evaluationContext: EvaluationContextState;
  associatedTags: EntityState<IDomainType> & {
    isLoading: boolean;
    errorMsg: string;
  };
  evaluations: EvaluationState;
  assignedEvaluations: AssignedEvaluationState;
  tags: TagsState;
  automationRules: AutomationRuleState;
  automationRulesCount: AutomationRuleCount;
  chats: {
    channels: any[];
    unreadChannels?: any[];
  };
  recommendationLoading: boolean;
  recommendationError: boolean;
  boundedChats: {
    id: string;
    providerId: string;
    patientId: string;
    recommendations: string[];
    recommendationType: string;
    action: {
      type: string;
      params: any;
    };
  }[];
  aiJobs: AIJob[];
  libraryContentBlocks: LibraryContentBlocksPage;
}

// REVIEW THIS WAS THE OLDER STATE
// export interface ConversationState {
//   isLoading: boolean;
//   errorMsg: string;
//   chatbots: IChatbot[];
//   connectionStatus: number;
//   messages: [];
//   sendingMessage: boolean;
//   loadEarlier: boolean;
//   isLoadingEarlier: boolean;
//   channelUrl: string;
//   sendbirdStatus: number;
// }

/**
 * SessionType types
 */
export interface ISessionType {
  id: string;
  description: string;
  name: string;
  serviceByTypeCount?: number;
}

export interface ICreateSessionTypeDTO {
  description?: string;
  name?: string;
}

export interface IUpdateSessionTypeDTO {
  id: string;
  description?: string;
  name?: string;
}

export interface SessionTypeState {
  isLoading: boolean;
  errorMsg: string;
  sessionTypes: ISessionType[];
}

/**
 * PlanItem types
 */
export interface IPlanItem {
  id: string;
  name: string;
  RevampPlanRemove: { completeXTimes: number; reference: string };
  completedState: string; // VIEW_SELECTED_EDUCATION
  navigatesTo: string; // 'EDUCATION'
  planToken: number;
  progressState: string; // VIEW_SELECTED_EDUCATION
  referenceId: string;
  type: string; // 'EDUCATION'
}

export interface ICreatePlanItemDTO {
  RevampPlanRemove: { completeXTimes: number; reference: string };
  completedState: string;
  name: string;
  navigatesTo: string;
  planToken: number;
  progressState: string;
  referenceId: string;
  type: string;
  filters?: string;
}

export interface IUpdatePlanItemDTO {
  id: string;
  RevampPlanRemove?: { completeXTimes: number; reference: string };
  completedState?: string;
  name?: string;
  navigatesTo?: string;
  planToken?: number;
  progressState?: string;
  referenceId?: string;
  type?: string;
  filters?: string;
}

export interface IAssignedPlanItem {
  planItem: {
    RevampPlanRemove: {
      completeXTimes: number;
      reference: string;
    };
    completedState: string;
    id: string;
    name: string;
    navigatesTo: string;
    planToken: number;
    progressState: string;
    referenceId: string;
    type: string;
  };
  priority: boolean;
  status: string;
}

export interface IAddAssignedPlanItemDTO {
  planItemIds: Array<string>;
  userId: string;
}

export interface PlanItemState {
  isLoading: boolean;
  isSaving: boolean;
  errorMsg: string;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  planItems: IPlanItem[];
}

export type IProfileElementInfo = {
  id?: string;
  key: string;
  type: string;
  method: string;
  values?: string[];
};

export interface IProfileElement {
  profileElementInfo: IProfileElementInfo;
  usage: {
    conversationIds: string[];
    dctIds: string[];
    patientsCount: number;
  };
}

export interface ProfileElementsState {
  isLoading: boolean;
  isSaving: boolean;
  errorMsg: string;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  profileElementList: IProfileElement[];
}

export interface IConversation {
  conversationId: string;
  name: string;
  version: number;
  assignableBy: any;
  assignedTo: any;
  dctCount: number;
  selfContained: boolean;
  lastUpdated: string;
  defaultPriority: string;
  status: string;
  avatar?: string;
}

export interface ConversationsListState {
  isLoading: boolean;
  errorMsg: string;
  conversations: IConversation[];
  totalRecords?: number;
}

export interface IEvaluation {
  evaluationId: string;
  name: string;
  version: number;
  dctCount: number;
  appointmentsCount: number;
  lastUpdated: string;
  status: string;
  description: string;
  rejectionTemplate: string[];
  cptCodes: string[];
  avatar?: string;
}

export interface EvaluationsListState {
  isLoading: boolean;
  errorMsg: string;
  evaluations: IEvaluation[];
  totalRecords?: number;
}

export interface IRevampType {
  contentfulEntryId: string;
  id: string;
  lastUpdated: string;
  name: string;
  parent: string;
  type: 'REVAMP_TYPE' | string;
  children: Array<IRevampTypeChild>;
}

export interface IRevampTypeChild {
  id: string;
  name: string;
  revampMetaData: IRevampMetaData;
}

export interface IRevampMetaData {
  actionButtons: Array<unknown>;
  backgroundImage: string;
  description: {
    subtitle: string;
    type: string;
    values: Array<unknown>;
  };
  displayType: string;
  inputType: string;
  majorQuestion: string;
  mappedValue: string;
  maxSelection: number;
  minSelection: number;
  popups: Array<unknown>;
  profileElement: { id: string; key: string; type: string; values: Array<string> };
  renderType: string;
  responseBased: boolean;
  significantQuestion: boolean;
  valuesGroups: Array<IValuesGroup>;
}

export interface IValuesGroup {
  colorCode: string;
  highLabel: string;
  icon: string;
  lowLabel: string;
  name: string;
  values: Array<unknown>;
}

export interface RevampTypesListState {
  isLoading: boolean;
  errorMsg: string;
  revampTypes: IRevampType[];
}

export interface ITagType {
  tagId: string;
  name: string;
  type: string;
  color: string;
  importance: string;
  assignmentMethod: string;
  associationCount: number;
  minScore: string;
  maxScore: string;
}

export interface TagsState {
  isLoading: boolean;
  errorMsg: string;
  tags: ITagType[];
}

export interface IDctContentBlock {
  cbId: string;
  cbProfileElementDetails: Array<{
    isCBMapped: boolean;
    key: string;
    valuesList: Array<{
      assignmentMethod: string;
      timeStamp: string;
      value: string;
    }>;
  }>;
  cbType: 'single-select' | string;
  choices?: Array<any>;
  isAttemptable: boolean;
  required?: boolean;
  responderType?: string;
  exerciseContent?: string;
  text: string;
  subtitle?: string;
  userResponse: {
    commentsCount: number;
    providerNarrative: string;
    score: number;
    userResponseList: Array<string>;
  };
}

export interface ICBResponse {
  cbId: string;
  cbType: 'dct' | string;
  dctContentBlockList: Array<IDctContentBlock>;
  responderType?: string;
  dctId: string;
  isAttemptable: boolean;
  text: string;
}

export interface IAlfieContentBlock {
  cbid: string;
  answers: string[];
  reviewerResponses: string[];
  wrongAlfieChoices: string[];
  amendNote?: string;
  from: number;
  to: number;
}

export interface IAlfieEvaluation {
  evaluationId: string;
  contentBlocks: IAlfieContentBlock[];
}

export interface Transcript {
  text: string;
  speaker: string;
  timestamp: string;
}

export interface InferredPriority {
  name: string;
  type: string;
  domainElementId: string;
  reason: string;
  code: string;
  quote: string;
  confidence: number;
  timestampFrom: number;
  timestampTo: number;
}

export interface IAlfieEvaluations {
  evaluations: IAlfieEvaluation[];
  sessionAudioUrl: string;
  speaker0: string;
  speaker1: string;
  transcriptParts: Transcript[];
  inferredPriorities: InferredPriority[];
}

export interface IEvaluationContext {
  alfieEvaluations?: IAlfieEvaluations;
  cbResponseList: Array<ICBResponse & IDctContentBlock>;
  corrections: Map<string, string[]>;
  sessionId?: string;
  evaluationId: string;
  contextId: string;
  name: string;
  status: string;
  alfieValidationFinalized?: boolean;
  alfieValidationInProgress?: boolean;
  aiCompletionStatus?: string;
}

export interface EvaluationContextState {
  isLoading: boolean;
  errorMsg: string;
  evaluationContext: IEvaluationContext;
  toggleStatus: boolean;
  evaluationHead: EvaluationItemNode;
}

export enum CbStatus {
  NOT_ATTEMPTED,
  SKIPPED,
  ANSWERED,
  NOT_APPLICABLE,
}

export type EvaluationItemNode = {
  isFirst?: boolean;
  next: EvaluationItemNode;
  status: CbStatus;
  text: string;
  subtitle: string;
  cbId: string;
  cbType: string;
  required: boolean;
  dctId: string;
  dctScore: number;
  isAttemptable: boolean;
  isDCTScoreable: boolean;
  usePreviousResponses: boolean;
  fullPage: boolean;
  responderType: string;
  cbProfileElementDetails: any[];
  choices: any[];
  children: EvaluationItemNode[];
  userResponse: {
    score: number;
    providerNarrative: string;
    answeredBy?: string;
    reason?: string;
    quote?: string;
    userResponseList: string[];
  };
  helperText: string;
  ratingScale: {
    lowLabel: string;
    highLabel: string;
    values: number[];
  };
  evaluationResponses?: any[];
  prompt?: string;
  educationContentSlug?: string;
  exerciseContent?: string;
};

export interface EvaluationState {
  isLoading: boolean;
  isSaving: boolean;
  errorMsg: string;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  evaluations: Partial<IEvaluation>[];
}

export interface AssignedEvaluationState {
  isLoading: boolean;
  isSaving: boolean;
  errorMsg: string;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  evaluations: IAssignedEvaluation[];
}

export interface IAssignedEvaluation {
  id: string;
  name: string;
  status: string;
  rejectionTemplate: string[];
  completedAt?: string;
  evaluationStats: {
    forProviderCount: number;
    totalCb: number;
    totalExercises: number;
    totalRequiredCb: number;
    unanswered: number;
  };
  description: string;
  progress: number;
  isAiGenerated?: boolean;
  evaluationAssignmentMethod: string;
  dctProgress: {
    id: string;
    name: string;
    score: number;
    isScorable: boolean;
    progress: number;
  }[];
}

export interface IGetDCTsParams {
  searchQuery?: any;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  sortBy?: string;
  status?: DCT_STATUS;
  type?: string;
  assignmentCount?: boolean;
  reportingView?: boolean;
  isScorable?: boolean;
  isAiGenerated?: boolean;
}

export interface IDCTDetail {
  id: string;
  displayInReport: boolean;
  lastUpdated: string;
  name: string;
  nextDraftId: string;
  scorable: boolean;
  status: string;
  version: number;
  prompt: string;
  description: string;
  responderType: string;
  usePreviousResponses: boolean;
  fullPage: boolean;
}

export interface IDCTScoring {
  description: string;
  maxScore: number;
  minScore: number;
  negativeRange: {
    colorCode: string;
    maxScore: number;
    minScore: number;
  };
  neutralRange: {
    colorCode: string;
    maxScore: number;
    minScore: number;
  };
  outcomeElementKey: string;
  positiveRange: {
    colorCode: string;
    maxScore: number;
    minScore: number;
  };
  positivityDirection: string;
  scoreElementKey: string;
  scoreRule: string;
  veryNegativeRange: {
    colorCode: string;
    maxScore: number;
    minScore: number;
  };
}

export interface IDCTAssignment {
  appearedInConversations: {
    conversationId: string;
    conversationName: string;
    version?: string;
    assignments?: number;
    completed?: number;
    inProgress?: number;
    notStarted?: number;
  }[];
  assigned: number;
  assignedTo: {
    date: string;
    imageUrl: string;
    patientId: string;
    patientName: string;
    status: string;
  }[];
  completed: number;
  dct: IDCTDetail;
  dctName: string;
  inProgress: number;
  notStarted: number;
}

export interface LibraryContentBlock {
  basicInfo: {
    activityData: {
      itemsNeeded: string;
      timeNeeded: string;
      title: number;
    };
    choices: {
      choice: string;
      score: number;
      value: string;
    }[];
    dataType: string;
    dropdowns: {
      label: string;
      values: string[];
    }[];
    educationContentSlug: string;
    educationSearchKeyword: string;
    name: string;
    placeholder: string;
    popupText: string;
    ratingScale: {
      assignmentRanges: {
        elementValue: string;
        maxValue: number;
        minValue: number;
      }[];
      highLabel: string;
      lowLabel: string;
      values: [];
    };
    referenceId: string;
    text: string;
    type: string;
    variableMapping: {
      name: string;
      scope: string;
    };
  };
  cbId: string;
  dctId: string;
  displayLogics: {
    key: string;
    metaText: string;
    rule: string;
    type: string;
    value: string;
  }[];
  helperText: string;
  prompt: string;
  rawDisplayLogic: string;
  relatedProfileElements: string[];
  required: boolean;
  routing: string[];
  routingOption: string;
  ruleAggregator: string;
  subtitle: string;
  status: string;
}

export interface LibraryContentBlocksPage {
  totalPages: number;
  currentPage: number;
  totalRecords: number;
  results: LibraryContentBlock[];
  isLoading: boolean;
}
