import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fontWeight, Heading } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { conversationActionCreators } from 'redux/modules/conversation';
import { getProfile } from 'redux/modules/profile/selectors';
import { AVATAR_COLOR_ARRAY, CONNECTIONS_TYPES } from 'constants/CommonConstants';
import { profileActionCreators } from 'redux/modules/profile';
import * as MemberService from 'services/member/member.service';
import debounce from 'debounce';
import Loader, { LoaderEnums } from '@confidant-health/lib/ui/templates/loader';

import { uuid4 } from 'lib/sendbird';
import { getAuth } from 'redux/modules/auth/selectors';

import { ChatListItem } from './ChatListItem';
import { ChatUser } from './ChatUser';
import { useStyles } from './Chats.styles';
import { IChatListItem } from './Chats.types';

type Props = {
  isEndedSession?: boolean;
  appointment?: IAppointment;
  careTeamListIds?: Array<string>;
  sessionStarted?: boolean;
  onClose: () => void;
};
const Chats: FC<Props> = ({
  isEndedSession = false,
  careTeamListIds,
  appointment,
  onClose,
  sessionStarted = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { profile, userGroups, isLoading } = useSelector(getProfile);
  const { isAdmin, meta } = useSelector(getAuth);

  const [selectedChatListItem, setSelectedChatListItem] = useState(null);
  const [chatListItemList, setChatListItemList] = useState(null);
  const [shouldShowChatSection, setShouldShowChatSection] = useState(true);
  const [showManageChats, setShowManageChats] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const populateChatListItems = connectionList => {
    const patientList = connectionList.filter(
      activeConnection =>
        activeConnection.type === CONNECTIONS_TYPES.PATIENT &&
        activeConnection?.connectionId === appointment?.participantId,
    );
    const careTeamMembers = connectionList.filter(
      activeConnection =>
        activeConnection.type !== CONNECTIONS_TYPES.CHAT_GROUP &&
        activeConnection.type !== CONNECTIONS_TYPES.PATIENT &&
        careTeamListIds.includes(activeConnection?.connectionId),
    );
    const updatedChatListItemList = [...patientList, ...careTeamMembers].map(activeConnection => {
      return {
        connectionId: activeConnection?.connectionId,
        name:
          activeConnection.name || `${activeConnection?.firstName || ''} ${activeConnection?.lastName || ''}`,
        avatar: activeConnection?.profilePicture || '',
        role: activeConnection?.type,
        colorCode: activeConnection?.colorCode,
        channelUrl: activeConnection?.channelUrl,
        nickName: activeConnection?.nickName,
        messages: activeConnection?.messages,
        isAdded: true,
      };
    });

    setChatListItemList(updatedChatListItemList);
    setSelectedChatListItem(updatedChatListItemList?.[0]);
  };
  const populateChats = debounce(() => {
    if (!isLoading) {
      MemberService.getPaginatedConnections({
        searchByConnectionId: [appointment?.participantId, ...careTeamListIds]?.toString(),
      })
        .then(res => {
          if (res.data?.results?.length > 0) {
            const connectionList = [
              ...res.data?.results?.map((item, index) => {
                return {
                  ...item,
                  colorCode:
                    index < AVATAR_COLOR_ARRAY.length - 1
                      ? AVATAR_COLOR_ARRAY[index]
                      : AVATAR_COLOR_ARRAY[AVATAR_COLOR_ARRAY.length - 1],
                };
              }),
              ...userGroups,
            ]?.sort(a => (a.type === CONNECTIONS_TYPES.PATIENT ? 1 : -1));

            populateChatListItems(connectionList);
          }
          setIsLoadingChat(false);
        })
        .catch(err => {
          console.log({ err });
          setIsLoadingChat(false);
        });
    }
  }, 3000);

  /**
   * @Name useEffect
   * @description This method is used to fetch connections.
   */

  useEffect(() => {
    if (!isAdmin) {
      dispatch(profileActionCreators.fetchUserGroups(meta?.userId));
    }
    setIsLoadingChat(true);
    populateChats();
  }, []);

  /**
   * @Name navigateToChatSection
   * @description This method is used to open chat window for the specific user
   */
  const navigateToChatSection = () => {
    const selectedChats = chatListItemList?.filter((chatListItem: IChatListItem) => chatListItem?.isAdded);
    if (selectedChats?.length > 0) {
      setSelectedChatListItem(selectedChats?.[0]);
      setShouldShowChatSection(true);
      setShowManageChats(false);
    } else {
      onClose();
    }
  };

  /**
   * @Name updateChatStatus
   * @params selectedContact
   * @description This method is used to update chat status ( Add / delete ) functionality
   */
  const updateChatStatus = (selectedContact: IChatListItem) => {
    if (selectedContact) {
      const updatedChatListItemList = chatListItemList?.map((chatListItem: IChatListItem) => {
        return {
          ...chatListItem,
          isAdded:
            chatListItem?.connectionId === selectedContact?.connectionId
              ? !chatListItem?.isAdded
              : chatListItem?.isAdded,
        };
      });
      setChatListItemList(updatedChatListItemList);
    }
  };

  /**
   * @Name handleAddMessage
   * @param msg
   * @description This method is used to handle add message functionality through redux call .
   */
  const handleAddMessage = (msg: Record<string, any>) => {
    const newMessage = !msg.hasFile
      ? {
          _id: uuid4(),
          message: {
            text: msg,
          },
          user: {
            userId: profile?.providerId,
            name: profile?.fullName,
            avatar: profile?.profileImage,
          },
          nickName: profile.fullName ?? 'no name',
          createdAt: new Date().getTime(),
          type: 'user',
          system: false,
        }
      : msg;
    dispatch(
      conversationActionCreators.sendMessage({
        payload: {
          message: newMessage,
        },
      }),
    );
  };

  /**
   * @Name useEffect
   * @description This method is used to init chat based on selected User
   */
  useEffect(() => {
    if (selectedChatListItem) {
      dispatch(
        conversationActionCreators.initChat({
          payload: {
            channelUrl: selectedChatListItem?.channelUrl,
            connection: {
              ...selectedChatListItem,
              messages: selectedChatListItem?.messages || [],
              nickname: selectedChatListItem?.nickName,
            },
            currentUser: {
              userId: profile?.providerId,
              nickname: profile?.fullName,
            },
          },
        }),
      );
    }
  }, [selectedChatListItem]);

  /**
   * @Name onChangeUser
   * @description This method is used to select other user from the list
   */
  const onChangeUser = (chatListItem: IChatListItem) => {
    setSelectedChatListItem(chatListItem);
  };

  /**
   * @Name openManageChats
   * @description This method is used to open Manage chats section
   */
  const openManageChats = () => {
    setShowManageChats(true);
    setShouldShowChatSection(false);
  };
  if (isLoading || isLoadingChat) {
    return (
      <Box padding="16px">
        <Loader type={LoaderEnums.CHAT_USER_CARD} numItems={12} />
      </Box>
    );
  }
  if (shouldShowChatSection && !showManageChats) {
    const selectedChatsList = chatListItemList?.filter(
      (chatListItem: IChatListItem) => chatListItem?.isAdded,
    );
    return (
      <ChatUser
        isEndedSession={isEndedSession}
        selectedChatsList={selectedChatsList as IChatListItem[]}
        onChangeUser={onChangeUser}
        onClose={onClose}
        handleAddMessage={handleAddMessage}
        selectedChatListItem={selectedChatListItem}
        openManageChats={openManageChats}
        sessionStarted={sessionStarted}
      />
    );
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Heading weight={fontWeight.BOLD} className={classes.title}>
          Manage Chats
        </Heading>
        <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
      </Box>
      <Box className={isEndedSession ? classes.body : classes.sessionBody}>
        {chatListItemList?.map((chatListItem: IChatListItem) => (
          <ChatListItem
            key={chatListItem?.connectionId}
            chatListItem={chatListItem}
            onClick={updateChatStatus}
            noRightAction={chatListItem?.connectionId === selectedChatListItem?.connectionId}
          />
        ))}
      </Box>
      <Box className={classes.footer}>
        <IconButton onClick={onClose} className={classes.backBtn}>
          Cancel
        </IconButton>
        <IconButton onClick={navigateToChatSection} variant={iconBtnType.PRIMARY} className={classes.doneBtn}>
          Done
        </IconButton>
      </Box>
    </Box>
  );
};

export { Chats };
