import { createAction } from 'redux-actions';

export const FETCH_CHATBOTS = 'chatbots/fetch';
export const FETCH_CHATBOTS_SUCCESSFUL = 'chatbots/fetchSuccessful';
export const FETCH_CHATBOTS_FAILED = 'chatbots/fetchFailed';
export const FETCH_CHATBOT_CONTACTS = 'chatbotContacts/fetch';
export const FETCH_CHATBOT_CONTACTS_SUCCESSFUL = 'chatbotContacts/fetchSuccessful';
export const FETCH_CHATBOT_CONTACTS_FAILED = 'chatbotContacts/fetchFailed';
export const FETCH_CHATBOT_ATTEMPT_HISTORY = 'chatbotAttemptHistory/fetch';
export const FETCH_CHATBOT_ATTEMPT_HISTORY_SUCCESSFUL = 'chatbotAttemptHistory/fetchSuccessful';
export const FETCH_CHATBOT_ATTEMPT_HISTORY_FAILED = 'chatbotAttemptHistory/fetchFailed';
export const CHAT_INITIALIZE = 'chat/INITIALIZE';
export const CHAT_INITIALIZING = 'chat/INITIALIZING';
export const CHAT_READY = 'chat/READY';
export const CHAT_INIT_FAILED = 'chat/INIT_FAILED';
export const CHAT_MARK_AS_READ = 'chat/MARK_AS_READ';
export const CHAT_ADD_MESSAGE = 'chat/ADD_MESSAGE';
export const CHAT_MESSAGE_RECEIVED = 'chat/MESSAGE_RECEIVED';
export const CHAT_SEND_MESSAGE = 'chat/SEND_MESSAGE';
export const CHAT_SEND_ATTACHMENT = 'chat/SEND_ATTACHMENT';
export const CHAT_ATTACHMENT_SENT = 'chat/ATTACHMENT_SENT';
export const CHAT_EXIT = 'chat/EXIT';
export const CHAT_DATA_SHARE_PROMPT_ANSWERED = 'chat/DATA_SHARE_PROMPT_ANSWERED';
export const CHAT_GROUP_UPDATED = 'chat/GROUP_UPDATED';
export const CHAT_MEDIA_UPLOADED = 'chat/MEDIA_UPLOADED';
export const CHAT_MEDIA_SENT = 'chat/MEDIA_SENT';
export const CHAT_MEDIA_UPLOAD_PROGRESS = 'chat/MEDIA_UPLOADED_PROGRESS';
export const CREATE_TODO = 'chat/CREATE_TODO';
export const FETCH_ALL_TODO = 'chat/FETCH_ALL_TODO';
export const FETCH_ALL_TODO_SUCCESSFUL = 'chat/FETCH_ALL_TODO_SUCCESSFUL';
export const ADD_RECENT_MESSAGES = 'chat/ADD_RECENT_MESSAGE';
export const REMOVE_RECENT_MESSAGES = 'chat/REMOVE_RECENT_MESSAGE';

export const GET_DATA_COLLECTION_TEMPLATES = 'dct/fetch';
export const GET_DATA_COLLECTION_TEMPLATES_SUCCESSFUL = 'dct/fetchSuccessful';
export const GET_DATA_COLLECTION_TEMPLATES_FAILED = 'dct/fetchFailed';

export const SENDBIRD_INIT = 'SENDBIRD_INIT';
export const SENDBIRD_CONNECT = 'SENDBIRD_CONNECT';
export const SENDBIRD_CONNECTED = 'SENDBIRD_CONNECTED';
export const SENDBIRD_RECONNECT = 'SENDBIRD_RECONNECT';
export const SENDBIRD_RECONNECTING = 'SENDBIRD_RECONNECTING';
export const SENDBIRD_CONNECTING = 'SENDBIRD_CONNECTING';
export const SENDBIRD_CONNECT_FAILED = 'SENDBIRD_CONNECT_FAILED';
export const SENDBIRD_CHANNELS_FETCHED = 'sendbird/CHANNELS_FETCHED';
export const SENDBIRD_FETCH_UNREAD_CHANNELS = 'sendbird/FETCH_UNREAD_CHANNELS';
export const SENDBIRD_UNREAD_CHANNELS_FETCHED = 'sendbird/UNREAD_CHANNELS_FETCHED';
export const FETCH_SESSION_TYPES = 'sessionTypes/fetch';
export const FETCH_SESSION_TYPES_SUCCESSFUL = 'sessionTypes/fetchSuccessful';
export const FETCH_SESSION_TYPES_FAILED = 'sessionTypes/fetchFailed';
export const CREATE_SESSION_TYPE = 'sessionTypes/create';
export const CREATE_SESSION_TYPE_SUCCESSFUL = 'sessionTypes/createSuccessful';
export const CREATE_SESSION_TYPE_FAILED = 'sessionTypes/createFailed';
export const UPDATE_SESSION_TYPE = 'sessionTypes/update';
export const UPDATE_SESSION_TYPE_SUCCESSFUL = 'sessionTypes/updateSuccessful';
export const UPDATE_SESSION_TYPE_FAILED = 'sessionTypes/updateFailed';
export const DELETE_SESSION_TYPE = 'sessionTypes/delete';
export const DELETE_SESSION_TYPE_SUCCESSFUL = 'sessionTypes/deleteSuccessful';
export const DELETE_SESSION_TYPE_FAILED = 'sessionTypes/deleteFailed';

export const FETCH_PLAN_ITEMS = 'planItems/fetch';
export const FETCH_PLAN_ITEMS_SUCCESSFUL = 'planItems/fetchSuccessful';
export const FETCH_PLAN_ITEMS_FAILED = 'planItems/fetchFailed';
export const CREATE_PLAN_ITEM = 'planItems/create';
export const CREATE_PLAN_ITEM_SUCCESSFUL = 'planItems/createSuccessful';
export const CREATE_PLAN_ITEM_FAILED = 'planItems/createFailed';
export const UPDATE_PLAN_ITEM = 'planItems/update';
export const UPDATE_PLAN_ITEM_SUCCESSFUL = 'planItems/updateSuccessful';
export const UPDATE_PLAN_ITEM_FAILED = 'planItems/updateFailed';
export const DELETE_PLAN_ITEM = 'planItems/delete';
export const DELETE_PLAN_ITEM_SUCCESSFUL = 'planItems/deleteSuccessful';
export const DELETE_PLAN_ITEM_FAILED = 'planItems/deleteFailed';

export const FETCH_ASSIGNED_PLAN_ITEMS = 'planItems/assigned/fetch';
export const FETCH_ASSIGNED_PLAN_ITEMS_SUCCESSFUL = 'planItems/assigned/fetchSuccessful';
export const FETCH_ASSIGNED_PLAN_ITEMS_FAILED = 'planItems/assigned/fetchFailed';
export const ADD_ASSIGNED_PLAN_ITEMS = 'planItems/assigned/add';
export const ADD_ASSIGNED_PLAN_ITEMS_SUCCESSFUL = 'planItems/assigned/addSuccessful';
export const ADD_ASSIGNED_PLAN_ITEMS_FAILED = 'planItems/assigned/addFailed';

export const FETCH_CONVERSATIONS = 'conversations/fetch';
export const FETCH_CONVERSATIONS_SUCCESSFUL = 'conversations/fetchSuccessful';
export const FETCH_CONVERSATIONS_FAILED = 'conversations/fetchFailed';
export const FETCH_EVALUATIONS = 'evaluations/fetch';
export const FETCH_EVALUATIONS_SUCCESSFUL = 'evaluations/fetchSuccessful';
export const FETCH_EVALUATIONS_FAILED = 'evaluations/fetchFailed';
export const FETCH_REVAMP_TYPES_LIST = 'revampTypesList/fetch';
export const FETCH_REVAMP_TYPES_LIST_SUCCESSFUL = 'revampTypesList/fetchSuccessful';
export const FETCH_REVAMP_TYPES_LIST_FAILED = 'revampTypesList/fetchFailed';

export const FETCH_EVALUATION_CONTEXT = 'conversations/fetchEvaluation';
export const FETCH_EVALUATION_CONTEXT_SUCCESSFUL = 'conversations/fetchEvaluationSuccessful';
export const FETCH_EVALUATION_CONTEXT_FAILED = 'conversations/fetchEvaluationFailed';

export const DELETE_EVALUATION = 'conversations/deleteEvaluation';
export const DELETE_EVALUATION_SUCCESSFUL = 'conversations/deleteEvaluationSuccessful';
export const DELETE_EVALUATION_FAILED = 'conversations/deleteEvaluationFailed';

export const RE_RUN_AI_ANALYSIS = 'conversations/reRunAIAnalysis';

export const FETCH_ASSOCIATED_TAG = 'conversations/fetchAssociatedTag';
export const FETCH_ASSOCIATED_TAG_SUCCESSFUL = 'conversations/fetchAssociatedTagSuccessful';
export const FETCH_ASSOCIATED_TAG_FAILED = 'conversations/fetchAssociatedTagFailed';

export const FETCH_TAGS = 'conversations/fetchTags';
export const FETCH_TAGS_SUCCESSFUL = 'conversations/fetchTagsSuccessful';
export const FETCH_TAGS_FAILED = 'conversations/fetchTagsFailed';

export const FETCH_PROFILE_ELEMENTS = 'conversation/builder/profileElementsList';
export const FETCH_PROFILE_ELEMENTS_SUCCESSFUL = 'conversation/builder/profileElementsListSuccessful';
export const FETCH_PROFILE_ELEMENTS_FAILED = 'conversation/builder/profileElementsListFailed';
export const FETCH_AI_JOBS = 'ai/fetchJobs';
export const FETCH_AI_JOBS_FAILED = 'ai/fetchJobsFailed';
export const FETCH_AI_JOBS_SUCCESSFUL = 'ai/fetchJobsSuccessful';
export const ADD_PROFILE_ELEMENT = 'conversation/builder/addProfileElement';
export const ADD_PROFILE_ELEMENT_SUCCESSFUL = 'conversation/builder/addProfileElementSuccessful';
export const ADD_PROFILE_ELEMENT_FAILED = 'conversation/builder/addProfileElementFailed';
export const UPDATE_PROFILE_ELEMENT = 'conversation/builder/updateProfileElement';
export const UPDATE_PROFILE_ELEMENT_SUCCESSFUL = 'conversation/builder/updateProfileElementSuccessful';
export const UPDATE_PROFILE_ELEMENT_FAILED = 'conversation/builder/updateProfileElementFailed';

export const TOGGLE_EVALUATION_STAUTS = 'conversations/toggleEvaluationStatus';

export const FETCH_EVALUATION = 'conversation/builder/evaluations';
export const FETCH_EVALUATION_SUCCESSFUL = 'conversation/builder/evaluationsSuccessful';
export const FETCH_EVALUATION_FAILED = 'conversation/builder/evaluationsFailed';

export const FETCH_ASSIGNED_EVALUATION = 'conversation/builder/assignedEvaluations';
export const FETCH_ASSIGNED_EVALUATION_SUCCESSFUL = 'conversation/builder/assignedEvaluationsSuccessful';
export const FETCH_ASSIGNED_EVALUATION_FAILED = 'conversation/builder/assignedEvaluationsFailed';

export const ADD_ASSIGNED_EVALUATION = 'conversation/builder/add/assignedEvaluations';
export const ADD_ASSIGNED_EVALUATION_SUCCESSFUL = 'conversation/builder/add/assignedEvaluationsSuccessful';
export const ADD_ASSIGNED_EVALUATION_FAILED = 'conversation/builder/add/assignedEvaluationsFailed';

export const FETCH_AUTOMATION_RULE_LIST = 'conversation/builder/automationRulesList/fetch';
export const FETCH_AUTOMATION_RULE_LIST_SUCCESSFUL =
  'conversation/builder/automationRulesList/fetchSuccessful';
export const FETCH_AUTOMATION_RULE_LIST_FAILED = 'conversation/builder/automationRulesList/fetchFailed';

export const CREATE_AUTOMATION_RULE = 'conversation/builder/automationRulesList/create';
export const CREATE_AUTOMATION_RULE_SUCCESSFUL = 'conversation/builder/automationRulesList/createSuccessful';
export const CREATE_AUTOMATION_RULE_FAILED = 'conversation/builder/automationRulesList/createFailed';

export const UPDATE_AUTOMATION_RULE = 'conversation/builder/automationRulesList/update';
export const UPDATE_AUTOMATION_RULE_SUCCESSFUL = 'conversation/builder/automationRulesList/updateSuccessful';
export const UPDATE_AUTOMATION_RULE_FAILED = 'conversation/builder/automationRulesList/updateFailed';

export const DELETE_AUTOMATION_RULE = 'conversation/builder/automationRulesList/delete';
export const DELETE_AUTOMATION_RULE_SUCCESSFUL = 'conversation/builder/automationRulesList/deleteSuccessful';
export const DELETE_AUTOMATION_RULE_FAILED = 'conversation/builder/automationRulesList/deleteFailed';

export const FETCH_AUTOMATION_COUNT = 'automation/count/fetch';
export const FETCH_AUTOMATION_COUNT_SUCCESSFUL = 'automation/count/fetchSuccessful';
export const FETCH_AUTOMATION_COUNT_FAILED = 'automation/count/fetchFailed';
export const FETCH_CHATS = 'chats/fetch';
export const FETCH_CHATS_SUCCESSFUL = 'chats/fetchSuccessful';
export const FETCH_CHAT_RECOMMENDATION = 'boundedChat/fetchRecommendation';
export const FETCH_CHAT_RECOMMENDATION_SUCCESSFUL = 'boundedChat/fetchRecommendationSuccessful';
export const FETCH_CHAT_RECOMMENDATION_FAILED = 'boundedChat/fetchRecommendationFailed';

export const DELETE_MEMBER_CONNECTION = 'conversation/deleteMemberConnection';

export const ADD_CONTENT_BLOCK = 'library/addContentBlock';
export const ADD_CONTENT_BLOCK_SUCCESSFUL = 'library/addContentBlockSuccessful';
export const ADD_CONTENT_BLOCK_FAILED = 'library/addContentBlockFailed';

export const FETCH_LIBRARY_CONTENT_BLOCKS = 'library/getContentBlock';
export const FETCH_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL = 'library/getContentBlockSuccessful';
export const FETCH_LIBRARY_CONTENT_BLOCKS_FAILED = 'library/getContentBlockFailed';

export const UPDATE_LIBRARY_CONTENT_BLOCKS = 'library/updateContentBlock';
export const UPDATE_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL = 'library/updateContentBlockSuccessful';
export const UPDATE_LIBRARY_CONTENT_BLOCKS_FAILED = 'library/updateContentBlockFailed';

export const DELETE_LIBRARY_CONTENT_BLOCKS = 'library/deleteContentBlock';
export const DELETE_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL = 'library/deleteContentBlockSuccessful';
export const DELETE_LIBRARY_CONTENT_BLOCKS_FAILED = 'library/deleteContentBlockFailed';

export const LOAD_MORE_MESSAGES = 'chats/loadMoreMessages';
export const LOAD_MORE_MESSAGES_SUCCESS = 'chats/loadMoreMessagesSuccess';
export const LOAD_MORE_MESSAGES_FAILED = 'chats/loadMoreMessagesFailed';

const createActionWrapper = action => {
  return createAction(
    action,
    data => data.payload,
    data => data.meta,
  );
};

export const conversationActionCreators = {
  fetchChatbots: createAction(FETCH_CHATBOTS),
  fetchChatbotContacts: createAction(FETCH_CHATBOT_CONTACTS),
  fetchChatbotAttemptHistory: createAction(FETCH_CHATBOT_ATTEMPT_HISTORY),
  initChat: createActionWrapper(CHAT_INITIALIZE),
  sendMessage: createActionWrapper(CHAT_SEND_MESSAGE),
  exitChat: createAction(CHAT_EXIT),
  dataSharingPromptAnswered: createActionWrapper(CHAT_DATA_SHARE_PROMPT_ANSWERED),
  chatGroupUpdated: createAction(CHAT_GROUP_UPDATED),
  fetchDCTs: createAction(GET_DATA_COLLECTION_TEMPLATES),
  fetchChats: createAction(FETCH_CHATS),
  fetchSessionTypes: createAction(FETCH_SESSION_TYPES),
  createSessionType: createAction(CREATE_SESSION_TYPE),
  updateSessionType: createAction(UPDATE_SESSION_TYPE),
  deleteSessionType: createAction(DELETE_SESSION_TYPE),
  fetchPlanItems: createAction(FETCH_PLAN_ITEMS),
  fetchAllTodo: createAction(FETCH_ALL_TODO),
  createPlanItem: createAction(CREATE_PLAN_ITEM),
  updatePlanItem: createAction(UPDATE_PLAN_ITEM),
  deletePlanItem: createAction(DELETE_PLAN_ITEM),
  fetchAssignedPlanItems: createAction(FETCH_ASSIGNED_PLAN_ITEMS),
  addAssignedPlanItems: createAction(ADD_ASSIGNED_PLAN_ITEMS),
  fetchConversations: createAction(FETCH_CONVERSATIONS),
  fetchEvaluations: createAction(FETCH_EVALUATIONS),
  fetchRevampTypesList: createAction(FETCH_REVAMP_TYPES_LIST),
  fetchEvaluationContext: createAction(FETCH_EVALUATION_CONTEXT),
  deleteEvaluation: createAction(DELETE_EVALUATION),
  fetchAssociatedTag: createAction(FETCH_ASSOCIATED_TAG),
  fetchAssociatedTagSuccess: createAction(FETCH_ASSOCIATED_TAG_SUCCESSFUL),
  fetchProfileElements: createAction(FETCH_PROFILE_ELEMENTS),
  addProfileElement: createAction(ADD_PROFILE_ELEMENT),
  updateProfileElement: createAction(UPDATE_PROFILE_ELEMENT),
  toggleEvaluationStatus: createAction(TOGGLE_EVALUATION_STAUTS),
  fetchAssignedEvaluations: createAction(FETCH_ASSIGNED_EVALUATION),
  addAssignedEvaluation: createAction(ADD_ASSIGNED_EVALUATION),
  fetchTags: createAction(FETCH_TAGS),
  fetchAutomationRules: createAction(FETCH_AUTOMATION_RULE_LIST),
  createAutomationRule: createAction(CREATE_AUTOMATION_RULE),
  updateAutomationRule: createAction(UPDATE_AUTOMATION_RULE),
  deleteAutomationRule: createAction(DELETE_AUTOMATION_RULE),
  fetchAutomationCount: createAction(FETCH_AUTOMATION_COUNT),
  reRunAIAnalysis: createAction(RE_RUN_AI_ANALYSIS),
  fetchChatRecommendation: createAction(FETCH_CHAT_RECOMMENDATION),
  addRecentMessage: createAction(ADD_RECENT_MESSAGES),
  fetchAIJobs: createAction(FETCH_AI_JOBS),
  removeRecentMessage: createAction(REMOVE_RECENT_MESSAGES),
  fetchUnreadChannels: createAction(SENDBIRD_FETCH_UNREAD_CHANNELS),
  evaluationContextUpdated: createAction(FETCH_EVALUATION_CONTEXT_SUCCESSFUL),
  deleteMemberConnection: createAction(DELETE_MEMBER_CONNECTION),
  addContentBlock: createAction(ADD_CONTENT_BLOCK),
  fetchLibraryContentBlocks: createAction(FETCH_LIBRARY_CONTENT_BLOCKS),
  updateLibraryContentBlocks: createAction(UPDATE_LIBRARY_CONTENT_BLOCKS),
  deleteLibraryContentBlocks: createAction(DELETE_LIBRARY_CONTENT_BLOCKS),
  loadMoreChatMessages: createAction(LOAD_MORE_MESSAGES),
};
