import { AxiosResponse } from 'axios';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { IPatchAppointmentRequest } from 'redux/modules/appointment/types';
import { baseRequest } from 'utils/HttpClient';

export type INewAppointmentRequest = {
  memberId: string;
  providerId: string;
  serviceId: string;
  startTime: string;
  instantAppointment?: boolean;
};

export type INewRecurringAppointmentRequest = {
  comment?: string;
  endTime: string;
  instantAppointment: boolean;
  isRecurring: boolean;
  memberId: string;
  paymentDetails?: {
    amountPaid: number;
    brand: string;
    chargeId: string;
    insuranceName: string;
    last4: string;
    paymentMethod: string;
  };
  primaryConcern?: string;
  providerId: string;
  recurringType: string;
  serviceId: string;
  startTime: string;
};
export const createNewAppointment = (payload: INewAppointmentRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.CREATE_APPOINTMENT, payload);
};

export const createNewRecurringAppointment = (
  payload: INewRecurringAppointmentRequest,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.CREATE_RECURRING_APPOINTMENT, payload);
};

export const validateRecurringAppointment = (
  payload: INewRecurringAppointmentRequest,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.VALIDATE_RECURRING_APPOINTMENT, payload);
};

export const getNextAppointment = (participantId = null, includePending = false): Promise<AxiosResponse> => {
  let query = null;
  if (participantId) {
    query = {
      participantId,
      includePending,
    };
  }
  return baseRequest(ApiEndpoints.FETCH_NEXT_APPOINTMENT, null, null, query);
};

export const getPreviousCompletedAppointment = (memberId, olderThan): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.FETCH_PREVIOUS_COMPLETED_APPOINTMENT, null, null, { memberId, olderThan });
};
export const allAppointmentsCounts = (payload: any): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ALL_APPOINNTMENT_COUNTS, payload || {});
};
export type IProviderSelfAppointmentRequest = {
  participantId: string;
  serviceId: string;
  slot: {
    end: number;
    start: number;
  };
  day: number;
  month: number;
  year: number;
  primaryConcern: string;
  timeZone: string;
};

export type ISearchAppointmentParams = {
  startTime?: string;
  endTime?: string;
  timezone?: string;
  type: string;
  revamp?: boolean;
  participantIds?: string;
  paymentMethod?: string;
  states?: string;
  serviceIds?: string;
  statuses?: string;
  patientStatus?: string;
  practitionerStatus?: string;
  sortBy?: string;
  orderBy?: string;
  page: number;
  size: number;
  searchQuery?: string;
  dateStatus?: string;
  timeZone?: string;
  vbcFlag?: boolean;
  practitionerDesignations?: string;
  includeOtherProviders?: boolean;
};
export const getAppointments = (queryParams: ISearchAppointmentParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS, null, null, queryParams);
};
export const exportAppointments = (queryParams: ISearchAppointmentParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.EXPORT_APPOINTMENTS, null, null, queryParams);
};

export const getPastAppointments = (
  pathParams: { userId: string },
  queryParams?: { fetchNoShow: boolean },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_PAST, null, pathParams, queryParams);
};

export const getPastAppointmentDetails = (pathParams: { appointmentId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PAST_APPOINTMENT_DETAILS, null, pathParams);
};

export const getProviderServices = (queryParams: { providerId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_SERVICES_BY_ID, null, null, queryParams);
};

export type ISearchScheduleParams = {
  participantIds?: string;
  memberId?: string;
  serviceId?: string;
  timezone?: string;
  duration?: number;
  providerRoles?: string;
  startDate?: string;
  endDate?: string;
  orderBy?: string;
  sortBy?: string;
  startTime?: string;
  endTime?: string;
  month?: number;
  year?: number;
  daysOfWeek?: string;
  state?: string;
  payerId?: string;
  page?: number;
  viewAppointments?: boolean;
  size?: number;
  viewProviderDetails?: boolean;
};

export const searchSchedule = (queryParams: ISearchScheduleParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.SEARCH_SCHEDULE, null, null, queryParams);
};

export const patchAppointment = (
  bodyRequest: IPatchAppointmentRequest,
  pathParams: {
    appointmentId: string;
  },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.PATCH_APPOINTMENT, bodyRequest, pathParams);
};

export const getAppointmentById = (pathParams: { appointmentId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_BY_ID, null, pathParams);
};

export const listProviders = () => {
  return baseRequest(ApiEndpoints.LIST_APPOINTMENT_ELIGIBLE_PROVIDERS, null);
};

export const startOrJoinCall = (
  bodyRequest: { channelUrl: string },
  queryParams?: { isDirectCall?: boolean },
) => {
  return baseRequest(ApiEndpoints.START_OR_JOIN_CALL, null, bodyRequest, queryParams);
};

export const leaveCall = (bodyRequest: { channelUrl: string; userId: string }) => {
  return baseRequest(ApiEndpoints.LEAVE_CALL, null, bodyRequest);
};

export const getAppointmentCptCodes = (appointmentId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_CPT_CODE, null, { appointmentId });
};

export const appointmentsDownload = (
  memberId: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.APPPINTMENT_DOWNLOAD, null, { memberId }, { startDate, endDate });
};

type AddNoteAppointmentPayload = {
  assessment?: string;
  objective?: string;
  plan?: string;
  skip: boolean;
  subjective?: string;
};
export const addNoteAppointment = (
  bodyRequest: AddNoteAppointmentPayload,
  pathParams: { appointmentId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_NOTE_APPOINTMENT, bodyRequest, pathParams);
};

export const fetchMasterScheduleProviders = (pathParams: { id: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.MARK_REJECT_PROVIDER_FEE, null, pathParams);
};

export const getAllProviderServices = (queryParams): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_ALL_UNIQUE_SERVICES, undefined, undefined, queryParams);
};

export const getAvailableSlots = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.SEARCH_SCHEDULE, undefined, undefined, bodyRequest);
};
