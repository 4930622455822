import { AppState } from 'redux/store/types';
import { StateState } from './types';

export const stateSelector = (state: AppState): StateState => state.state;

export const availableStatesSelector = (state: AppState) => state.profile.providerDetails;

export const selectStatePayers = (state: AppState) => state.state.payers;

export const selectStatePayer = (state: AppState) => state.state.selectedPayer;

export const selectCpts = (state: AppState) => state.state.cpts;
