import { Box, useMediaQuery } from '@mui/material';
import { theme } from '@confidant-health/lib/theme';

import ReactApexChart from 'react-apexcharts';
import BoxShadow from 'components/v2/BoxShadow';

import { DonutChartSeriesProps, getChartOpts } from './chart.config';
import { getColumnsForStatsChart, useStyles } from './index.styles';

type Props = {
  total: number;
  legendSeries: DonutChartSeriesProps;
};
const ChartBoard = ({ legendSeries = [], total }: Props) => {
  const classes = useStyles({});
  const isMobile = useMediaQuery(theme.breakpoints.down(1430));
  return (
    <BoxShadow>
      <Box
        className={classes.chart}
        sx={{
          '& .apexcharts-legend.apexcharts-align-center.apx-legend-position-right': {
            top:
              legendSeries.length > 5
                ? isMobile
                  ? '9px !important'
                  : '24px !important'
                : legendSeries.length > 3
                ? '30px !important'
                : '56px !important',
            gridTemplateColumns: getColumnsForStatsChart(legendSeries?.length ?? 5),
          },
          '& span.apexcharts-legend-marker': {
            top: '0px !important',
            width: '24px !important',
            height: '24px !important',
          },
          '& span.apexcharts-legend-marker[rel="1"]::before': {
            content: `"${legendSeries?.[0]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[0]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[0]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="2"]::before': {
            content: `"${legendSeries?.[1]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[1]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[1]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="3"]::before': {
            content: `"${legendSeries?.[2]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[2]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[2]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="4"]::before': {
            content: `"${legendSeries?.[3]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[3]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[3]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="5"]::before': {
            content: `"${legendSeries?.[4]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[4]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[4]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="6"]::before': {
            content: `"${legendSeries?.[5]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[5]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[5]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="7"]::before': {
            content: `"${legendSeries?.[6]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[6]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[6]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="8"]::before': {
            content: `"${legendSeries?.[7]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[7]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[7]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="9"]::before': {
            content: `"${legendSeries?.[8]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[8]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[8]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
          '& span.apexcharts-legend-marker[rel="10"]::before': {
            content: `"${legendSeries?.[9]?.data[0] ?? 0}"`,
            color: `${legendSeries?.[9]?.color} !important`.replaceAll(/"/g, "'"),
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${legendSeries?.[9]?.bg}`.replaceAll(/"/g, "'"),
            borderRadius: '50%',
          },
        }}
      >
        <ReactApexChart
          width={800}
          options={getChartOpts(
            legendSeries,
            {
              total,
              title: '',
              size: '70%',
            },
            true,
            `${total} total`,
          )}
          series={legendSeries?.map(item => item.data[0])}
          type="donut"
          height={150}
        />
      </Box>
    </BoxShadow>
  );
};

export default ChartBoard;
