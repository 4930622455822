import { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusText,
  SignOffStatus,
} from '@confidant-health/lib/constants/CommonConstants';

import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { iconBtnStyle, iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import AppointmentCancel from '../../../provider/appointments/components/appointment-cancel';

import { payeeTypes } from '../../payments/Payments.constants';

import { getAuth } from '../../../../redux/modules/auth/selectors';
import { selectAssignedEvaluationState } from '../../../../redux/modules/conversation/selectors';

import { IProps, IEvaluationProps } from './types';
import { useStyles } from './ApptDetailDrawerNew.styles';
import {
  IAssignedEvaluation,
  ICBResponse,
  IDctContentBlock,
  IEvaluationContext,
} from '../../../../redux/modules/conversation/types';
import { conversationActionCreators } from '../../../../redux/modules/conversation/actions';
import AddEvaluation from './AddEvaluation';
import EvaluationDetail from '../../../provider/priorities/appointments/evaluation-detail';
import AppointmentScheduling from '../../../provider/appointments/components/appointment-scheduling';
import history from '../../../../utils/history';
import { getDemographicDetails } from '../../../../services/member/member.service';
import CreateConnectionModal from '../../../../layouts/base/CreateConnectionModal';
import {
  createMemberConnection,
  getEvaluationContext,
} from '../../../../services/conversation/conversation.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { PreviewExercisesDrawer } from '../../../provider/priorities/appointments/evaluation-detail/PreviewExercisesDrawer';

const AppointmentDetailDrawer: FC<IProps> = ({
  open,
  onClose,
  appointment,
  evaluations,
  fetchAppointments,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin, meta } = useSelector(getAuth);
  const { isLoading, evaluations: assignedEvaluationsData } = useSelector(selectAssignedEvaluationState);
  const [selectedEvaluation, setSelectedEvaluation] = useState<IAssignedEvaluation>(null);
  const [assignedEvaluations, setAssignedEvaluations] = useState<IAssignedEvaluation[]>([]);
  const [openAddEvaluation, setOpenAddEvaluation] = useState(false);
  // const [openCancelAppt, setOpenCancelApptModal] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment | null>(null);
  const [showReschedule, setShowReschedule] = useState(false);
  const [isCreateConnection, setIsCreateConnection] = useState(false);
  const [previewDrawerOpen, setPreviewDrawerOpen] = useState(false);
  const [evaluationContext, setEvaluationContext] = useState<IEvaluationContext>(null);
  const { status, signOffStatus, patientStatus, practitionerStatus, statusChangingAuthority } =
    appointment || {};
  const showButtons =
    /* appointment?.status !== AppointmentStatus.FULFILLED && */
    appointment?.status !== AppointmentStatus.CANCELLED &&
    appointment?.status !== AppointmentStatus.DECLINED &&
    appointment?.status !== AppointmentStatus.NO_SHOW &&
    appointment?.status !== AppointmentStatus.INACTIVE;

  let statusText = AppointmentStatusText[status];
  if (status === AppointmentStatus.FULFILLED && signOffStatus === SignOffStatus.DRAFTED) {
    statusText = 'Waiting for Sign off';
  }
  if (status === AppointmentStatus.PROPOSED || status === AppointmentStatus.PENDING) {
    if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Waiting on Member';
    } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Waiting on Provider';
    }
  } else if (status === AppointmentStatus.CANCELLED) {
    if (patientStatus === AppointmentStatus.DECLINED) {
      statusText = 'Cancelled - M';
    } else if (practitionerStatus === AppointmentStatus.DECLINED) {
      statusText = 'Cancelled - P';
    }
  }
  if (status === AppointmentStatus.NO_SHOW) {
    if (statusChangingAuthority === 'PATIENT') {
      statusText = 'No Show - M';
    } else if (statusChangingAuthority === 'PRACTITIONER') {
      statusText = 'No Show - P';
    }
  }
  if (status === AppointmentStatus.BOOKED) {
    statusText = 'Scheduled';
  }

  const allContentBlocks = useMemo((): Array<ICBResponse & IDctContentBlock> => {
    if (!evaluationContext) {
      return [];
    }
    const blocks = evaluationContext.cbResponseList
      .map(cb => {
        if (cb.cbType === 'dct' && cb.dctContentBlockList) {
          return cb.dctContentBlockList.map(innerCb => {
            return {
              ...innerCb,
              responderType: cb.responderType,
            };
          });
        }
        return cb;
      })
      .flat();
    return blocks as Array<ICBResponse & IDctContentBlock>;
  }, [evaluationContext]);

  useEffect(() => {
    setSelectedAppointment(appointment);
  }, [appointment]);

  useEffect(() => {
    setAssignedEvaluations(assignedEvaluationsData);
  }, [assignedEvaluationsData]);

  const handleEvaluationDeletion = evaluationId => {
    const isAssigned = assignedEvaluations.some(evalId => evalId.id === evaluationId);
    if (isAssigned) {
      const { appointmentId } = appointment;
      dispatch(conversationActionCreators.deleteEvaluation({ appointmentId, evaluationId }));
    }
  };

  const onAddEvaluation = (selectedValues: string[]) => {
    dispatch(
      conversationActionCreators.addAssignedEvaluation({
        evaluationIds: selectedValues,
        appointmentId: appointment?.appointmentId,
      }),
    );
    setOpenAddEvaluation(false);
  };

  const toggleCancelDrawer = () => {
    setShowCancel(!showCancel);
    // fetchAppointments();
  };

  const toggleRescheduleDrawer = () => {
    setShowReschedule(!showReschedule);
    if (!showReschedule === false) {
      fetchAppointments();
      onClose();
    }
  };

  const handleChatBtn = async () => {
    try {
      const { data } = await getDemographicDetails({ connectionId: appointment?.patientId });
      if (meta?.authority === 'BILLING_SUPPORT' || meta?.authority === 'ADMIN') {
        if (data?.isConnected === false) {
          setIsCreateConnection(true);
        } else {
          history.push(`/admin/chats?userId=${appointment?.patientId}&type=members`);
        }
      } else {
        history.push(`/admin/chats?userId=${appointment?.patientId}&type=members`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addConnectionWithMember = async () => {
    try {
      const bodyRequest = {
        suggestedFirstUser: meta.userId,
        suggestedSecondUser: appointment?.patientId,
      };
      const response = await createMemberConnection(bodyRequest);
      setIsCreateConnection(false);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Connection with member created successfully.',
          }),
        );
        history.push(`/admin/chats?userId=${appointment?.patientId}&type=members`);
      }
    } catch (error) {
      setIsCreateConnection(false);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error?.data?.errors[0].endUserMessage,
        }),
      );
    }
  };

  const closeExercisesPreview = () => {
    setSelectedEvaluation(null);
    setPreviewDrawerOpen(false);
  };

  const formatContactNumber = phoneNumber => {
    if (phoneNumber) {
      const last10Digits = phoneNumber?.slice(-10);
      // Format the number as (XXX) XXX-XXXX
      const formattedNumber = `(${last10Digits?.slice(0, 3)}) ${last10Digits?.slice(
        3,
        6,
      )}-${last10Digits?.slice(6)}`;
      return formattedNumber;
    }
    return '';
  };

  const Evaluation = ({ item, onDelete, index }: IEvaluationProps) => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        sx={{ '& svg': { width: 20 } }}
      >
        <Box display="flex" flexDirection="column" gap="4px">
          <Text className={classes.infoValue}>{item.name}</Text>
          {item.evaluationStats.totalExercises !== 0 && (
            <Button
              variant={btnType.TEXT}
              className={classes.btnOpenChat}
              onClick={async () => {
                const { data } = await getEvaluationContext({
                  appointmentId: appointment?.appointmentId,
                  evaluationId: item.id,
                });
                setEvaluationContext(data);
                setSelectedEvaluation(item);
                setPreviewDrawerOpen(true);
              }}
              id={`eval-${index}-view-exercise`}
            >
              View {item.evaluationStats.totalExercises} exercises
            </Button>
          )}
        </Box>
        {appointment.status !== AppointmentStatus.FULFILLED && (
          <Box>
            <IconButton
              icon="delete-outlined-2"
              size={btnSize.SMALL}
              style={iconBtnStyle.ERROR}
              onClick={() => onDelete && onDelete(item.id)}
              id={`eval-${index}-delete`}
            />
            <IconButton
              className={classes.btnEvaluation}
              icon="arrow-right"
              size={btnSize.SMALL}
              style={iconBtnStyle.SECONDARY}
              onClick={() => setSelectedEvaluation(item)}
              id={`eval-${index}-open`}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        // setAssignedEvaluations([]);
        onClose();
        fetchAppointments();
      }}
      title="Appointment details"
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      {!!appointment && (
        <>
          <Box flex={1}>
            <Box paddingBottom="32px" display="flex" gap="16px" flexDirection="column">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Text weight={fontWeight.BOLD} className={classes.title}>
                    {appointment.serviceName}
                  </Text>
                  <Text weight={fontWeight.MEDIUM} className={clsx(classes.text16, classes.duration)}>
                    {appointment.serviceDuration} minutes session
                  </Text>
                </Box>
              </Box>
              <Box display="flex" justifyContent="left" alignItems="center" gap={1}>
                <Badge
                  variant={badgeType.FILLED}
                  style={AppointmentStatusBagde[appointment.status]}
                  className={classes.badge}
                >
                  {statusText?.toLowerCase()?.includes('cancel') ? (
                    <span className={classes.cancelledStatusText}>{statusText}</span>
                  ) : (
                    statusText
                  )}
                </Badge>
                <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
                  {dayjs(appointment.startTime).format('MMMM DD, YYYY')} --{' '}
                  {dayjs(appointment?.startTime).format('hh:mma')} -{' '}
                  {dayjs(appointment.endTime).format('hh:mma')}
                </Text>
              </Box>
            </Box>
            <Box
              flex={1}
              display="flex"
              flexDirection="row"
              padding="32px 0"
              borderTop={`1px solid ${colors.neutral200}`}
            >
              <Box display="flex" flexDirection="column" gap="16px" width="50%">
                <Text className={classes.infoLabel}>Member</Text>
                <Box className={classes.flexBetween}>
                  <ProfileInfo
                    type="member"
                    photo={appointment?.member?.photo ?? appointment?.participantImage}
                    nickName={
                      appointment?.patientFirstName && appointment?.patientLastName
                        ? `${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`
                        : appointment?.participantName ?? 'No Name'
                    }
                    isProvider={!isAdmin}
                    fullName={appointment?.patientUuid}
                    memberId={appointment?.participantId || appointment?.patientId}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                alignItems="flex-end"
                justifyContent="center"
                gap="8px"
              >
                <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
                  {formatContactNumber(appointment?.patientPhoneNumber)}
                </Text>
                <Box>
                  <Button
                    variant={btnType.TEXT}
                    className={classes.btnOpenChat}
                    onClick={() => {
                      void handleChatBtn();
                    }}
                  >
                    Message member
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              flex={1}
              display="flex"
              flexDirection="row"
              padding="32px 0"
              borderTop={`1px solid ${colors.neutral200}`}
            >
              <Box display="flex" flexDirection="column" gap="16px" width="50%">
                <Text className={classes.infoLabel}>Provider</Text>
                <Box className={classes.flexBetween}>
                  <ProfileInfo
                    type="provider"
                    photo={appointment?.practitionerImage}
                    fullName={appointment?.practitionerName}
                    role={appointment?.practitionerDesignation}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                alignItems="flex-end"
                justifyContent="center"
              >
                <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
                  {formatContactNumber(appointment?.practitionerPhoneNumber)}
                </Text>
                {/* <Box>
                  <Button
                    variant={btnType.TEXT}
                    className={classes.btnOpenChat}
                    onClick={() => {
                      void handleChatBtn();
                    }}
                  >
                    Message provider
                  </Button>
                </Box> */}
              </Box>
            </Box>
            <Box
              className={classes.flexBetween}
              gap={2}
              padding="32px 0"
              borderTop={`1px solid ${colors.neutral200}`}
            >
              <Box>
                <Text className={classes.infoLabel}>State</Text>
                <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text16, classes.info)}>
                  {appointment?.patientState?.trim() !== '' ? appointment?.patientState : '-'}
                </Text>
              </Box>
              {appointment?.prePayment && (
                <>
                  <Box>
                    <Text className={classes.infoLabel}>Payment method</Text>
                    <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text16, classes.info)}>
                      {appointment?.prePayment?.paymentMethod
                        ? payeeTypes[appointment?.prePayment?.paymentMethod]
                        : '-'}
                    </Text>
                  </Box>
                  <Box>
                    <Text className={classes.infoLabel}>Amount paid</Text>
                    <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text16, classes.info)}>
                      ${appointment.prePayment.amountPaid}
                    </Text>
                  </Box>
                </>
              )}
            </Box>
            <Box className={classes.flexColumn} borderTop={`1px solid ${colors.neutral200}`}>
              <Box className={classes.flexBetween} padding="32px 0">
                {assignedEvaluations && assignedEvaluations?.length > 0 && (
                  <Text className={classes.infoLabel}>Assigned evaluations</Text>
                )}
                {statusText !== 'Cancelled' &&
                  statusText !== 'Cancelled - M' &&
                  statusText !== 'Cancelled - P' &&
                  appointment.status !== AppointmentStatus.FULFILLED && (
                    <IconButton
                      variant={iconBtnType.OUTLINE}
                      className={clsx(classes.btnReschedule)}
                      icon="plus"
                      onClick={() => setOpenAddEvaluation(true)}
                    >
                      Assign evaluation
                    </IconButton>
                  )}
              </Box>
              {isLoading ? (
                <Box display="flex" justifyContent="center" paddingY="10px">
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                </Box>
              ) : (
                <>
                  <Box
                    className={assignedEvaluations?.length > 1 && classes.evaluations}
                    paddingBottom="32px"
                  >
                    {assignedEvaluations?.map((evaluation, index) => (
                      <Evaluation
                        key={`${index}-${evaluation.id}`}
                        item={evaluation}
                        index={index}
                        onDelete={() => handleEvaluationDeletion(evaluation.id)}
                      />
                    ))}
                  </Box>
                </>
              )}
            </Box>
            {openAddEvaluation && evaluations?.length > 0 && (
              <AddEvaluation
                isOpen={openAddEvaluation}
                onClose={() => setOpenAddEvaluation(false)}
                evaluations={evaluations}
                assignedEvaluations={assignedEvaluations}
                appointment={appointment}
                onAddEvaluation={onAddEvaluation}
                onRemoveEvaluation={handleEvaluationDeletion}
              />
            )}
          </Box>
          {showButtons && (
            <Box borderTop={`1px solid ${colors.neutral200}`} paddingTop="32px">
              <Box className={classes.flexBetween} gap={2}>
                <IconButton
                  variant={iconBtnType.OUTLINE}
                  className={clsx(classes.btn, classes.btnReschedule)}
                  onClick={toggleRescheduleDrawer}
                  icon="calendar-primary-outlined-square"
                >
                  Reschedule
                </IconButton>
                <IconButton
                  variant={iconBtnType.OUTLINE}
                  className={clsx(classes.btn, classes.btnCancel)}
                  onClick={toggleCancelDrawer}
                  icon="x-circle-outlined"
                >
                  Cancel appointment
                </IconButton>
              </Box>
            </Box>
          )}
          {showCancel && (
            <AppointmentCancel
              open={showCancel}
              onClose={() => {
                toggleCancelDrawer();
                fetchAppointments();
                onClose();
              }}
              appointment={selectedAppointment}
              updateAppointment={setSelectedAppointment}
            />
          )}
          {selectedEvaluation && (
            <EvaluationDetail
              isOpen={!!selectedEvaluation}
              onClose={() => setSelectedEvaluation(null)}
              evaluation={selectedEvaluation}
              appointmentId={appointment.appointmentId}
              memberId={appointment.patientId}
              isView={!!selectedEvaluation?.isAiGenerated && meta.authority === 'ADMIN'}
              // isView
            />
          )}
          {isCreateConnection && (
            <CreateConnectionModal
              icon="conversations-filled"
              open={isCreateConnection}
              title="Add Connection"
              content="Do you want to create connection with this member?"
              lbtnLabel="Cancel"
              rbtnLabel="Confirm"
              onClose={() => setIsCreateConnection(false)}
              onCancel={() => setIsCreateConnection(false)}
              onSubmit={addConnectionWithMember}
              isLoading={false}
              isDelete={false}
            />
          )}
          <AppointmentScheduling
            open={showReschedule}
            onClose={toggleRescheduleDrawer}
            appointment={selectedAppointment}
            updateAppointment={setSelectedAppointment}
            hideCancelBtn
          />
          {previewDrawerOpen && (
            <PreviewExercisesDrawer
              open={previewDrawerOpen}
              onClose={closeExercisesPreview}
              exercises={allContentBlocks.filter(cb => cb.cbType === 'exercise')}
              evaluationName={selectedEvaluation?.name}
              selectedExerciseCbId=""
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export { AppointmentDetailDrawer };
