import { Box } from '@mui/material';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge } from '@confidant-health/lib/ui/atoms/badge';
import { useNavigate } from 'react-router-dom';

import cx from 'clsx';
import avatarIcon from 'assets/images/avatar.png';
import groupAvatarIcon from 'assets/images/group-avatar.png';
import { CONNECTIONS_TYPES } from 'constants/CommonConstants';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';

import Typography from '../Typography';
import { typography } from '../Typography/index.constant';

import { useStyles } from './index.styles';

export enum ProfileInfoType {
  GROUP = 'group', // default
  MEMBER = 'member',
}
export enum ProfileInfoRole {
  PROVIDER = 'Provider', // default
  SUPERVISING = 'Supervising provider',
  CARE = 'Care navigator',
  COACH = 'Coach',
  MEMBER = 'Member',
  DESIGNATION = 'Designation',
  PRACTITIONER = 'Practitioner',
  MATCHMAKER = 'Matchmaker',
  THERAPIST = 'Therapist',
  NURSE_PRACTITIONER = 'Nurse Practitioner',
  PSYCHIATRIST = 'Psychiatrist',
  PRESCRIBER = 'Prescriber',
}

type Props = {
  fullName: string;
  src?: string;
  role?: ProfileInfoRole;
  type?: ProfileInfoType;
  uuid?: string;
  memberId?: string;
  isProvider?: boolean;
};

const ProfileInfo = ({
  fullName = '',
  src = '',
  role = ProfileInfoRole.PROVIDER,
  type = ProfileInfoType.MEMBER,
  uuid = '',
  memberId = null,
  isProvider = false,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    return memberId
      ? navigate(
          type === 'member' ? `/${isProvider ? 'provider' : 'admin'}/members/${memberId}?name=dashboard` : '',
        )
      : null;
  };

  return (
    <Box display="flex" gap={1.5} alignItems="center" onClick={handleClick}>
      {src ? (
        <Avatar variant={avatarType.CIRCLE} size={40} src={src} name={fullName} />
      ) : (
        <Box maxWidth={40} maxHeight={40} width={40} height={40}>
          <img
            src={type !== ProfileInfoType.GROUP ? avatarIcon : groupAvatarIcon}
            alt="avatar empty img"
            width="100%"
            height="100%"
          />
        </Box>
      )}

      <Box display="flex" flexDirection="column">
        <Typography {...typography.body.normal.small.semibold}>{fullName}</Typography>
        {role === CONNECTIONS_TYPES.PATIENT || role === CONNECTIONS_TYPES.CARE_TEAM ? (
          <Text className={classes.time} weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
            {uuid}
          </Text>
        ) : (
          <Badge
            className={cx({
              [classes.badge]: true,
              [classes.provider]: role === ProfileInfoRole.PROVIDER,
              [classes.supervising]: role === ProfileInfoRole.SUPERVISING,
              [classes.care]: role === ProfileInfoRole.CARE,
              [classes.coach]: role === ProfileInfoRole.COACH,
              [classes.member]: role === ProfileInfoRole.MEMBER,
              [classes.designation]: role === ProfileInfoRole.DESIGNATION,
            })}
          >
            <Typography {...typography.body.normal.xsmall.semibold}>{role}</Typography>
          </Badge>
        )}
      </Box>
    </Box>
  );
};

export default ProfileInfo;
