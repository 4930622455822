import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

// components
import { Box } from '@mui/material';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { TextField } from '@confidant-health/lib/ui/atoms/text-field';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { RadioGroup, radioGroupType } from '@confidant-health/lib/ui/molecules/radio-group';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';

import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';

import { IPatient, IDemographicDetails, IDemographicData } from 'redux/modules/profile/types';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import DatePickerInput from 'pages/admin/claim-detail/components/DatePicker';
import { Toggle, positionType } from '@confidant-health/lib/ui/atoms/toggle';

// styles
import { useStyles } from '../../MemberDetail.styles';

type IPersonalInformationProps = {
  memberId: string;
};

const PersonalInformation: FC<IPersonalInformationProps> = ({ memberId }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(true);
  const {
    selectedPatient = {} as IPatient,
    demographicDetails = {} as IDemographicDetails,
    demographicMetadata = {} as IDemographicData,
  } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const [statusList] = useState([
    { label: 'New Member', value: 'New member' },
    { label: 'Referral', value: 'Referral' },
    { label: 'Active', value: 'Active' },
    { label: 'Referral Active', value: 'Referral Active' },
    { label: 'Successful Discharge', value: 'Successful Discharge' },
    { label: 'Self Discharge', value: 'Self Discharge' },
    { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
    { label: 'Lost to Care', value: 'Lost to Care' },
    { label: 'Not Admitted to Care', value: 'Not admitted to care' },
    { label: 'DIY', value: 'DIY' },
    { label: 'Ghost', value: 'Ghost' },
  ]);
  const classes = useStyles({});
  const [errors, setErrors] = useState({ nickName: null, dateOfBirth: null });
  const profile = useSelector(getProfile);
  const [invalidDate, setInvalidDate] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '', // api connected
    lastName: '', // api connected
    nickName: '',
    mobilePhone: '',
    dateOfBirth: '',
    genderIdentity: '',
    genderPronoun: '',
    gender: '',
    levelOfEngagementStatus: '',
    levelOfEngagementSubStatus: '',
    primaryInterests: [],
    isActiveChatGroup: false,
    referralSources: [],
    referralPartners: [],
    eprescribeEntry: false,
  });

  const dispatch = useDispatch();

  const getFormatQueryParams = () => {
    return {
      pageNumber: 0,
      pageSize: 10000,
      search: '',
    };
  };
  const referralPartnerOptions = useMemo(
    () =>
      profile?.referralPartners?.partners?.map(rPartners => {
        return {
          refPartnerName: rPartners.name,
          refPartnerId: rPartners.refPartnerId,
        };
      }) || [],
    [profile?.referralPartners?.partners],
  );

  const fetchReferralPartnersCall = () => {
    dispatch(profileActionCreators.fetchReferralPartners(getFormatQueryParams()));
  };

  useEffect(() => {
    dispatch(profileActionCreators.fetchDemographicMetadata());
    fetchReferralPartnersCall();
  }, []);
  useEffect(() => {
    setFormData({
      firstName: demographicDetails.firstName || '',
      lastName: demographicDetails.lastName || '',
      nickName: demographicDetails.nickName || '',
      mobilePhone: demographicDetails.mobilePhone || '',
      dateOfBirth:
        demographicDetails.dateOfBirth && demographicDetails.dateOfBirth !== ''
          ? dayjs(demographicDetails.dateOfBirth).toISOString()
          : '',
      genderIdentity: demographicDetails.genderIdentity || 'Male',
      gender: demographicDetails.sex || 'Male',
      genderPronoun: demographicDetails.genderPronoun || '',
      levelOfEngagementStatus: demographicDetails?.levelOfEngagementStatus || '',
      levelOfEngagementSubStatus: '',
      isActiveChatGroup: demographicDetails.isActiveChat || false,
      primaryInterests: demographicDetails.onboardingGoals?.map(goal => ({ title: goal, value: goal })) || [],
      referralSources: demographicDetails.referralSources?.map(goal => ({ title: goal, value: goal })) || [],
      referralPartners:
        demographicDetails.referralPartners?.map(item => {
          if (item.value) {
            return item.value;
          }
          return item;
        }) || [],
      eprescribeEntry: demographicDetails.eprescribeEntry || false,
    });
  }, [selectedPatient, demographicDetails]);
  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => setOpenDrawer(false);

  useEffect(() => {
    if (Object.keys(selectedPatient)?.length !== 0 && Object.keys(demographicDetails)?.length !== 0) {
      selectedPatient.member.firstName = demographicDetails.firstName;
      selectedPatient.member.lastName = demographicDetails.lastName;
      selectedPatient.member.nickName = demographicDetails.nickName;
      selectedPatient.dob = demographicDetails.dateOfBirth;
      dispatch(profileActionCreators.selectedPatient(selectedPatient));
    }
  }, [demographicDetails]);

  const onChangeField = (field: string) => (e: any) => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: null }));
    }
    setFormData(old => ({
      ...old,
      [field]: !isAdmin && field === 'isActiveChatGroup' ? !old.isActiveChatGroup : e.target?.value,
    }));
  };

  useEffect(() => {
    if (formData) {
      if (
        formData.firstName.trim()?.length === 0 &&
        formData.lastName.trim()?.length === 0 &&
        formData.nickName.trim()?.length === 0
      ) {
        setErrors({
          ...errors,
          nickName: 'Either First, Last, or Preferred name is required',
        });
        setFormValid(false);
      } else {
        setFormValid(!invalidDate);
        setErrors({
          ...errors,
          nickName: null,
        });
      }
    }
  }, [formData, invalidDate]);
  const onChangePhone = (value: any) => {
    if (value !== undefined) {
      setFormData(old => ({
        ...old,
        mobilePhone: value,
      }));
    }
  };
  const onChangeDateOfBirth = (date: Dayjs) => {
    if (date?.isValid()) {
      if (date?.isBefore(dayjs())) {
        setFormData({ ...formData, dateOfBirth: date.toISOString() });
        setFormValid(true);
        setErrors({ ...errors, dateOfBirth: null });
        setInvalidDate(false);
      } else {
        setErrors({ ...errors, dateOfBirth: 'Invalid date of birth' });
        setFormValid(false);
        setInvalidDate(true);
      }
    } else {
      setFormData({ ...formData, dateOfBirth: '' });
      setFormValid(true);
      setErrors({ ...errors, dateOfBirth: null });
      setInvalidDate(false);
    }
  };
  const onChangeLevelOfEngagementStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(old => ({
      ...old,
      levelOfEngagementStatus: e.target.value,
    }));
  };

  const onChangePrimaryInterests = (e: React.ChangeEvent<HTMLInputElement>, values: any, reason: string) => {
    if (reason === 'removeOption') {
      setFormData(old => ({
        ...old,
        primaryInterests: values,
      }));
      return;
    }
    const value = e.target.innerText;
    const isExisted = formData.primaryInterests.some(item => item.value === value);
    if (!isExisted) {
      setFormData(old => ({
        ...old,
        primaryInterests: [...old.primaryInterests, { title: value, value }],
      }));
    }
  };

  const onChangeReferralSources = (e: React.ChangeEvent<HTMLInputElement>, values: any, reason: string) => {
    if (reason === 'removeOption') {
      setFormData(old => ({
        ...old,
        referralSources: values,
      }));
      return;
    }
    const value = e.target.innerText;
    const isExisted = formData.referralSources.some(item => item.value === value);
    if (!isExisted) {
      setFormData(old => ({
        ...old,
        referralSources: [...old.referralSources, { title: value, value }],
      }));
    }
  };
  const handleOnSubmit = () => {
    if (
      formData.firstName.trim()?.length === 0 &&
      formData.lastName.trim()?.length === 0 &&
      formData.nickName.trim()?.length === 0
    ) {
      setErrors({
        ...errors,
        nickName: 'Either First, Last, or Preferred name is required',
      });
      setFormValid(false);
      return;
    }
    dispatch(
      profileActionCreators.updateDemographicDetails({
        demographicDetails: {
          ...formData,
          fullName: formData.nickName,
          dateOfBirth: formData.dateOfBirth !== '' ? dayjs(formData.dateOfBirth).format('YYYY-MM-DD') : '',
          gender: formData.genderIdentity,
          mobilePhone: formData.mobilePhone,
          primaryInterests: formData.primaryInterests.map(({ value }) => value),
          referralSources:
            formData.referralSources?.length !== 0 ? formData.referralSources.map(({ value }) => value) : [],
          referralPartners:
            formData.referralPartners?.length !== 0
              ? formData.referralPartners?.map(({ refPartnerId }) => refPartnerId)
              : [],
        },
        memberId,
      }),
    );
    handleCloseDrawer();
  };
  const onChangeReferralPartner = (_: any, value) => {
    const isExisted = formData.referralPartners.some(item => item.value === value);
    if (!isExisted) {
      setFormData(old => ({
        ...old,
        referralPartners: Object.values(value), // { title: value, value }],
      }));
    }
    // setSelectedReferralPartners(value);
  };

  const renderInfo = (label: string, text: string, className = '') => {
    return (
      <Box className={className}>
        <TextField label={label} text={text || '-'} />
      </Box>
    );
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Personal information
        </Heading>
        <Link onClick={handleOpenDrawer}>Edit personal information</Link>
      </div>
      <Box gridTemplateColumns="4fr 1fr" rowGap="32px" display="grid">
        <Box
          className={classes.cardContentSection}
          gridTemplateColumns="2fr 3fr"
          rowGap="32px"
          display="grid"
        >
          {renderInfo('UID', demographicDetails.uuid)}
          {renderInfo(
            'Sex',
            demographicDetails.sex
              ? `${demographicDetails.sex} ${
                  demographicDetails.genderPronoun ? `(${demographicDetails.genderPronoun})` : ''
                }`
              : '',
          )}
          {renderInfo('Reward', demographicDetails.revampReward)}
          {renderInfo(
            'Date of birth',
            demographicDetails.dateOfBirth
              ? `${dayjs(demographicDetails.dateOfBirth, 'YYYY-MM-DD').format(
                  GLOBAL_DATE_FORMAT,
                )} (${dayjs().diff(demographicDetails.dateOfBirth, 'y')} y.o)`
              : '-',
          )}
          {renderInfo('Primary interests', demographicDetails.onboardingGoals?.join(', '))}
          {renderInfo(
            'Level of engagement',
            Object.keys(demographicDetails)?.length === 0
              ? '-'
              : `${
                  demographicDetails?.levelOfEngagementStatus !== null
                    ? demographicDetails?.levelOfEngagementStatus
                    : '-'
                },${
                  demographicDetails?.levelOfEngagementSubStatus !== null
                    ? demographicDetails?.levelOfEngagementSubStatus
                    : '-'
                }`,
          )}
          {renderInfo('Referral source', demographicDetails.referralSources?.join(', '))}
          {renderInfo('E-prescribe entry', demographicDetails.eprescribeEntry ? 'Enabled' : 'Disabled')}
        </Box>
      </Box>

      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Edit personal information"
        submitBtnTitle="update"
        disableSubmit={!formValid}
        onSubmit={handleOnSubmit}
      >
        <div className={classes.inputsContainer}>
          <div className={classes.inputWrapper}>
            <label htmlFor="first__name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                First name
              </Text>
            </label>
            <Input
              name="firstName"
              value={formData.firstName || ''}
              onChange={onChangeField('firstName')}
              size={inputSize.M}
              placeholder="Enter your first name"
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="last__name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Last name
              </Text>
            </label>
            <Input
              value={formData.lastName || ''}
              name="lastName"
              size={inputSize.M}
              onChange={onChangeField('lastName')}
              placeholder="Enter your last name"
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="first__name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Preferred name
              </Text>
            </label>
            <Input
              name="fullName"
              value={formData.nickName || ''}
              onChange={onChangeField('nickName')}
              size={inputSize.M}
              placeholder="Enter your preferred name"
            />
            <TextError errorMsg={errors.nickName} />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="date__of__birth">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Date of birth</span>
              </Text>
            </label>
            <DatePickerInput date={dayjs(formData.dateOfBirth)} onChange={onChangeDateOfBirth} />
            <TextError errorMsg={errors.dateOfBirth} />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              Sex
            </Text>
            <RadioGroup
              value={formData.genderIdentity}
              variant={radioGroupType.HORIZONTAL}
              onChange={onChangeField('genderIdentity')}
              options={demographicMetadata.gender.map(gender => ({ label: gender, value: gender }))}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Pronouns</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              value={formData.genderPronoun}
              onChange={onChangeField('genderPronoun')}
              options={demographicMetadata.genderPronouns.map(pronoun => ({
                label: pronoun,
                value: pronoun,
              }))}
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="mobilePhone">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Phone Number
              </Text>
            </label>
            <Input
              value={formData.mobilePhone || ''}
              name="mobilePhone"
              placeholder="Enter phone number"
              onChange={onChangePhone}
              size={inputSize.M}
              variant={inputType.PHONE}
              fullWidth
            />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Level of Engagement</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              options={statusList}
              onChange={onChangeLevelOfEngagementStatus}
              value={formData.levelOfEngagementStatus}
            />
            {/* {(formData.levelOfEngagementStatus === 'Diy' ||
              formData.levelOfEngagementStatus === 'Active') && (
              <Select
                variant={selectType.SECONDARY}
                options={formData.levelOfEngagementStatus === 'Diy' ? subStatusDiyList : subStatusActiveList}
                onChange={onChangeLevelOfEngagementSubStatus}
                value={formData.levelOfEngagementSubStatus}
              />
            )} */}
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="multi__reward">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Primary interests</span>
              </Text>
            </label>
            <Input
              variant={inputType.TAGS}
              name="onboardingGoals"
              onChange={onChangePrimaryInterests}
              size={inputSize.M}
              value={formData.primaryInterests}
              options={demographicMetadata.primaryInterests.map(interest => ({
                title: interest,
                value: interest,
              }))}
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="date__of__birth">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Referral Sources</span>
              </Text>
            </label>
            <Input
              value={formData.referralSources}
              // placeholder="Select R"
              name="referralSources"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              // className={classes.valuesInput}
              options={demographicMetadata.referralSources.map(source => ({
                title: source,
                value: source,
              }))}
              onChange={onChangeReferralSources}
              variant={inputType.TAGS}
              size={inputSize.M}
              // onBlur={setTouched('serviceTypes')}
              // fullWidth
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="referralPartners">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Referral Partners</span>
              </Text>
            </label>
            <Input
              value={formData.referralPartners}
              placeholder="Select referral partners"
              name="referralPartners"
              isOptionEqualToValue={(option, value) => option.refPartnerId === value.refPartnerId}
              // className={classes.valuesInput}
              getOptionLabel={option => `${option.refPartnerName}`}
              options={referralPartnerOptions}
              onChange={onChangeReferralPartner}
              variant={inputType.TAGS}
              size={inputSize.M}
              // onBlur={setTouched('serviceTypes')}
              // fullWidth
            />
            {/* <Input
              fullWidth
              name="referralPartners"
              value={selectedReferralPartners || null}
              options={referralPartnerOptions ?? []}
              getOptionLabel={option => `${option.label}`}
              renderOption={(props: any, option) => (
                <Box key={option.value} {...props}>
                  {option.label}
                </Box>
              )}
              size={inputSize.M}
              placeholder="Select referral partners"
              variant={inputType.TAGS}
              onChange={onChangeReferralPartner}
            /> */}
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="eprescribeEntry">
              <Toggle
                checked={formData?.eprescribeEntry}
                labelPosition={positionType.RIGHT}
                onChange={() => setFormData({ ...formData, eprescribeEntry: !formData?.eprescribeEntry })}
              >
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  <span>E-prescribe Entry</span>
                </Text>
              </Toggle>
            </label>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PersonalInformation;
