import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { getProfile } from 'redux/modules/profile/selectors';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { btnSize } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';

import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { ProfileInfoRole } from 'components/v2/ProfileInfo';
import { Icons } from '@confidant-health/lib/icons';
import { getProviderSchedule } from 'services/schedule/schedule.service';
import { CONNECTIONS_TYPES } from 'constants/CommonConstants';

import { useStyles } from './Chats.styles';

type Props = {
  careTeamMember?: any;
  setCurrentProviderId?: Dispatch<SetStateAction<string>>;
  setChatClicked?: Dispatch<SetStateAction<boolean>>;
  type?: string;
  isProvider?: boolean;
};

const CareTeamMemberItem = ({
  careTeamMember,
  setCurrentProviderId,
  setChatClicked,
  type,
  isProvider = false,
}: Props) => {
  const [showOfficeHours, setShowOfficeHours] = useState(false);
  const { activeConnections } = useSelector(getProfile);
  const [providerSchedule, setProviderSchedule] = useState({} as any);
  const [isLoadingSchedule, setIsLoading] = useState(false);
  const onClickShowOfficeHours = () => {
    setCurrentProviderId(careTeamMember.userId ?? '');
    setShowOfficeHours(!showOfficeHours);
  };
  const classes = useStyles({ collapsed: showOfficeHours });
  const getProviderScheduleCall = () => {
    const queryParams = {};
    setIsLoading(true);
    getProviderSchedule(careTeamMember.userId ?? careTeamMember.connectionId, queryParams)
      .then(res => {
        setProviderSchedule({
          id: careTeamMember.userId,
          availability: res.data.availability,
          timezone: res.data.timezone,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      (careTeamMember?.metaData?.type !== CONNECTIONS_TYPES.PATIENT &&
        type === CONNECTIONS_TYPES.CARE_TEAM) ||
      type === CONNECTIONS_TYPES.PRACTITIONER ||
      type === CONNECTIONS_TYPES.MATCH_MAKER ||
      type === CONNECTIONS_TYPES.CARE_NAVIGATOR ||
      type === CONNECTIONS_TYPES.CHAT_GROUP
    ) {
      getProviderScheduleCall();
    }
  }, [careTeamMember.userId]);

  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const hasSlotsOnDay = (DayName: string) => {
    if (providerSchedule?.availability?.length > 0) {
      const foundSlots = providerSchedule.availability?.find(item => item.day === DayName && item.active);
      if (foundSlots) {
        return true;
      }
    }
    return false;
  };
  const hasSlots = () => {
    if (providerSchedule?.availability?.length > 0) {
      const foundSlots = providerSchedule.availability?.find(item => item.day && item.active);
      if (foundSlots) {
        return true;
      }
    }
    return false;
  };
  const getSlotsByDayName = (DayName: string) => {
    if (providerSchedule?.availability?.length > 0) {
      const foundSlots = providerSchedule.availability?.find(item => item.day === DayName && item.active);
      if (foundSlots) {
        return foundSlots.slots;
      }
    }
    return [];
  };
  const getFormatedTime = (timestring: string) => {
    if (timestring && timestring !== '') {
      if (dayjs(timestring).minute() === 0) return dayjs(timestring).format('h a');
      return dayjs(timestring).format('h:m a');
    }
    return '';
  };

  const isActiveConnection = () => {
    return activeConnections.find(connection => connection.connectionId === careTeamMember.userId) || false;
  };

  const getDesignationText = () => {
    if (careTeamMember?.designation) {
      if (ProfileInfoRole[careTeamMember?.designation?.toUpperCase()])
        return ProfileInfoRole[careTeamMember?.designation?.toUpperCase()];
      return careTeamMember?.designation;
    }
    return ProfileInfoRole[careTeamMember?.metaData?.type || 'PROVIDER'];
  };

  return (
    <Box p={3} borderBottom={`1px solid ${colors.neutral100}`}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ProfileInfo
          type={careTeamMember?.metaData?.type === CONNECTIONS_TYPES.PATIENT ? 'member' : 'provider'}
          // eslint-disable-next-line jsx-a11y/aria-role
          role={getDesignationText()}
          fullName={
            isProvider ? careTeamMember?.name ?? careTeamMember?.nickName : careTeamMember?.name ?? null
          }
          photo={careTeamMember?.plainProfileUrl ?? (careTeamMember?.profilePicture || '')}
          nickName={
            careTeamMember?.metaData?.type === CONNECTIONS_TYPES.PATIENT
              ? careTeamMember?.userNickName ?? careTeamMember?.nickname
              : careTeamMember?.nickname ?? ''
          }
          memberId={
            careTeamMember?.metaData?.type === CONNECTIONS_TYPES.PATIENT ? careTeamMember?.userId : null
          }
          isProvider={isProvider}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={3}
          sx={{ '&>button': { gap: 1 } }}
        >
          {careTeamMember?.metaData?.type !== 'PATIENT' && (
            <Box onClick={onClickShowOfficeHours} className={classes.toggleWrapper}>
              <IconButton className={clsx(classes.chevronIcon, 'collapsable-icon')} icon="chevron-down" />
              <Typography {...typography.body.s.bold} className={classes.toggleText}>
                {showOfficeHours ? ' Hide office hours' : 'Show office hours'}
              </Typography>
            </Box>
          )}
          {isActiveConnection() && type === CONNECTIONS_TYPES.CARE_TEAM && (
            <IconButton
              icon="chat-square"
              size={btnSize.XSMALL}
              className={isActiveConnection() ? classes.chatBtn : classes.chatBtnDisabled}
              onClick={() => {
                setCurrentProviderId(careTeamMember.userId ?? '');
                setChatClicked(true);
              }}
            />
          )}
        </Box>
      </Box>
      {showOfficeHours && (
        <Box>
          {!isLoadingSchedule ? (
            hasSlots() ? (
              weekDays.map((weekday, index) =>
                hasSlotsOnDay(weekday) ? (
                  <Box key={index} py={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={2.5}>
                        <Typography {...typography.body.m.semibold}>{weekday}</Typography>
                      </Grid>
                      <Grid item xs={9.5} display="flex" justifyContent="flex-end">
                        <Box display="flex" gap={2} flexWrap="wrap" justifyContent="flex-end">
                          {getSlotsByDayName(weekday).map((slot, indx) => (
                            <Box
                              key={indx}
                              className={
                                getSlotsByDayName(weekday)?.length > 2
                                  ? classes.availableBadgeFitWidth
                                  : classes.availableBadge
                              }
                            >
                              <Typography {...typography.body.s.medium}>{`${getFormatedTime(
                                slot.start ?? '',
                              )} - ${getFormatedTime(slot.end ?? '')}`}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <></>
                ),
              )
            ) : (
              <Box py={3} display="flex" justifyContent="center" alignItems="center">
                <Typography {...typography.body.s.bold}>No slots available</Typography>
              </Box>
            )
          ) : (
            <Box
              borderBottom={`1px solid ${colors.neutral100}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={3}
            >
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CareTeamMemberItem;
