import { FC, memo, useMemo } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Text, fontWeight } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
// import { Menu } from '@confidant-health/lib/ui/molecules/menu';

import { IDomainElement } from 'redux/modules/profile/types';
import { useSelector } from 'react-redux';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
// import { AppointmentsFilterDates } from 'constants/CommonConstants';
import { DiagnosBottomCard } from '../diagnoses-card/DiagnosBottomCard';

import { SymptomChart } from '../../Symptoms/symptom-detail/symptom-chart';
import { useStyles } from './DiagnorsDetail.styles';
import { ProfileInfoRole } from '../../../../../../components/v2/ProfileInfo';

type Props = {
  open: boolean;
  onClose: () => void;
  diagnoses: IDomainElement;
  isNoDrawer?: boolean;
  isLifeEvent?: boolean;
  onClickFullReport?: (item: IDomainElement) => void;
  onClickNewReport: () => void;
  priorityType?: string;
};

const DiagnorsDetail: FC<Props> = memo(
  ({
    open,
    onClose,
    isNoDrawer,
    diagnoses,
    isLifeEvent,
    onClickNewReport,
    onClickFullReport,
    priorityType,
  }) => {
    const classes = useStyles();
    const { isAdmin } = useSelector(getAuth);
    const { profile } = useSelector(getProfile);

    const fullHistory = useMemo(() => {
      if (diagnoses) {
        const { history, priority, ...rest } = diagnoses;
        const current = { ...rest, priority, importanceLevel: priority };
        return history ? [current, ...history] : [current];
      }
      return [];
    }, [diagnoses]);

    // const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);

    // const times = AppointmentsFilterDates.map(time => ({
    //   ...time,
    //   onClick: () => setSelectedTime(time),
    // }));

    const renderContent = () => (
      <Box className={classes.container}>
        <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              All Reports - {diagnoses.name}
            </Text>
            {diagnoses.description && (
              <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
                {diagnoses.description}
              </Text>
            )}
          </Box>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
        <Box className={clsx(classes.content, { [classes.contentNoDrawer]: isNoDrawer })}>
          {!isNoDrawer && !isLifeEvent && (
            <Box className={classes.contentFilter}>
              <Text weight={fontWeight.BOLD} className={classes.contentTitle}>
                Importance level <Icons glyph="question-outlined" className={classes.question} />
              </Text>
              {/* <Menu icon="chevron-down" items={times} itemsWrapperClassName={classes.menuLabel}>
                <Text className={classes.menuLabel} level={textLevel.S}>
                  {selectedTime.label}
                </Text>
              </Menu> */}
            </Box>
          )}
          {!isLifeEvent && (
            <Box className={classes.contentChart}>
              <SymptomChart name={priorityType} items={fullHistory} />
            </Box>
          )}
          <Box>
            {fullHistory?.map((report, index) => (
              <DiagnosBottomCard item={report} showBadge key={index} onClickFullReport={onClickFullReport} />
            ))}
          </Box>
        </Box>
        {(profile?.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
          profile?.designation === ProfileInfoRole.THERAPIST ||
          isAdmin) && (
          <Box className={classes.footer}>
            <IconButton variant={iconBtnType.TEXT} onClick={onClose}>
              Close
            </IconButton>
            <IconButton
              onClick={onClickNewReport}
              className={classes.newReportBtn}
              variant={iconBtnType.PRIMARY}
            >
              Update report
            </IconButton>
          </Box>
        )}
      </Box>
    );

    if (isNoDrawer) {
      return renderContent();
    }

    return (
      <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
        {diagnoses && renderContent()}
      </Drawer>
    );
  },
);

DiagnorsDetail.displayName = 'DiagnorsDetail';

export { DiagnorsDetail };
